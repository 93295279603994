import React, { useState } from 'react';
import ActionBtns from './ActionBtns';
import Name from './Name';
import UserInfo from './UserInfo';
import UpdateModal from '../modal/UpdateModal';
import PasswordModal from '../modal/PasswordModal';
import ImageModal from '../modal/ImageModal';

const User = ({ obj }) => {
  const [isShowPasswordModal, setIsShowPasswordModal] = useState(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);
  const [isShowImageModal, setIsShowImageModal] = useState(false);
  const handleUpdateModal = (e) => {
    setIsShowUpdateModal(!isShowUpdateModal);
  };
  const handlePasswordModal = (e) => {
    setIsShowPasswordModal(!isShowPasswordModal);
  };
  const handleImageModal = (e) => {
    setIsShowImageModal(!isShowImageModal);
  };
  return (
    <>
      <div className="col-lg-4 mt-3">
        <div className="card card-green h-100 ">
          <div className="card-header">
            <div className="row">
              <Name
                pic={obj.pic}
                user={obj.user}
                setIsShowImageModal={setIsShowImageModal}
                isShow={isShowImageModal}
              />
              <ActionBtns
                eventUpdate={handleUpdateModal}
                eventPassword={handlePasswordModal}
              />
            </div>
          </div>
          <div className="card-body">
            <UserInfo
              mobile={obj.mobile}
              user={obj.user}
              is_trainee={obj.is_trainee}
            />
          </div>
        </div>
      </div>
      <UpdateModal
        isShow={isShowUpdateModal}
        handleCancel={handleUpdateModal}
        handleProceed={handleUpdateModal}
        obj={obj}
      />
      <PasswordModal
        isShow={isShowPasswordModal}
        handleCancel={handlePasswordModal}
        handleProceed={handlePasswordModal}
        obj={obj}
      />
      <ImageModal
        isShow={isShowImageModal}
        handleCancel={handleImageModal}
        handleProceed={handleImageModal}
        obj={obj}
      />
    </>
  );
};

export default User;
