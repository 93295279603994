import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import FileInput from '../../../utils/FileInput';
import { modalHeaderStyle } from '../../../utils/Style';
import Loading from '../../../utils/Loading';
import { defaultImageUrl } from '../../../utils/urls';
import { useDispatch } from 'react-redux';
import * as actionsCreator from '../../../utils/redux/userListReducer/actions';
import { instance } from '../../../utils/CustomFunctions';

const ImageModal = ({ obj, isShow, handleCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pic, setPic] = useState(obj.pic);

  //redux
  const dispatch = useDispatch();

  // API
  const postData = async (obj, url) => {
    setIsLoading(true);
    await instance
      .put(url, obj)
      .then((res) => dispatch(actionsCreator.updateUser(res.data.data)))
      .then(() => setIsLoading(false))
      .then(() => handleCancel())
      .catch((err) => console.log(err));
  };

  // Form Action
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append('pic', pic);
    postData(fd, `user-auth/api/upload-pic/${obj.id}/`);
  };

  return (
    <Modal show={isShow} onHide={handleCancel} centered>
      <Modal.Header
        className="text-center bg-dark-1 text-green-1 text-uppercase"
        style={modalHeaderStyle}
      >
        <strong>Update Profile</strong>
      </Modal.Header>
      {isLoading ? (
        <Modal.Body className="bg-dark-1">
          <Loading />
        </Modal.Body>
      ) : (
        <form onSubmit={(e) => handleOnSubmit(e)}>
          <img src={pic ? pic : defaultImageUrl} className="w-100" alt="" />
          <Modal.Body className="bg-dark-1">
            <FileInput
              required={true}
              title="Picture"
              url={obj.pic}
              onChange={(e) => setPic(e.target.files[0])}
            />
          </Modal.Body>
          <Modal.Footer className="bg-dark-1 ">
            <Button className="btn btn-secondary" onClick={handleCancel}>
              Close
            </Button>
            <Button className="btn btn-active-green" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};
export default ImageModal;
