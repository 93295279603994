import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteModal = ({ isShow, handleCancel, handleProceed }) => {
  return (
    <Modal show={isShow} onHide={handleCancel} centered>
      <Modal.Body className="bg-dark-1">
        <div>
          <strong className="text-green-1 bold">
            Are you sure you want to continue?
          </strong>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-dark-1">
        <Button className="btn btn-secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button className="btn btn-active-green" onClick={handleProceed}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteModal;
