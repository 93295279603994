import React, { useState, useEffect } from 'react';
import Title from './Title';
import Back from './Back';
import SubmitBtn from './SubmitBtn';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loading from '../../utils/Loading';
import { saveOtp } from '../../utils/redux/forgetPassReducer/actions';

export const OtpForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(saveOtp(otp, setLoading, setError, history));
  };

  return (
    <div className="container mt-5">
      <Back to={'/forget-password'} />
      <div className="row">
        <div className="col-lg-3" />
        <div className="col-lg-6">
          <div className="card card-green">
            <Title title={'Enter OTP'} />
            <div className="card-body">
              <div className="row text-center">
                <div className="col-lg-12">
                  <div className="alert alert-danger">
                    OTP Validations{' '}
                    {minutes === 0 && seconds === 0 ? null : (
                      <h1>
                        {' '}
                        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                      </h1>
                    )}
                  </div>
                  {error && <div className="alert alert-danger">{error}</div>}
                </div>
              </div>
              {loading ? (
                <Loading />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="row  mt-2 ">
                    <div className="col-lg-12">
                      <div className="textOnInput ">
                        <label htmlFor="inputText " className="text-green-1">
                          OTP
                        </label>
                        <input
                          required={true}
                          type="text"
                          className="search-input input-overlay trans-input"
                          name="otp"
                          id="otp"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <SubmitBtn />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
