import React from 'react';
import { Container, Col } from 'react-bootstrap/';

const Error = ({ description }) => {
  return (
    <Container className=" text-center justify-content-center mt-5">
      <div className="row">
        <Col>
          <div className="card bg-red-1">
            <div className="card-body">
              <p>{description}</p>
            </div>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default Error;
