import React, { useState } from 'react';
import Article from '../article/Article';
import SectionButtons from './SectionButtons';
import UpdateModal from './update/UpdateModal';
import DeleteModal from './delete/DeleteModal';
import AddModal from '../article/add/AddModal';

const Section = ({ section, index }) => {
  const [showAddArticle, setShowAddArticle] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="card section">
            <div className="card-header text-uppercase bg-grey-5">
              <div className="row text-blue-1">
                <div className="col-9 my-auto">
                  <h5>
                    <strong>{section.name}</strong>
                  </h5>
                </div>
                <div className="col-3 text-right my-auto">
                  <SectionButtons
                    section={section}
                    index={index}
                    setShowUpdateModal={setShowUpdateModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setShowAddArticle={setShowAddArticle}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              {section.articles.map((article, index) => {
                return (
                  <Article
                    key={article.id}
                    article={article}
                    index={index}
                    section={section}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <AddModal
        isShow={showAddArticle}
        openModal={setShowAddArticle}
        section={section}
      />
      <UpdateModal
        isShow={showUpdateModal}
        openModal={setShowUpdateModal}
        section={section}
      />
      <DeleteModal
        isShow={showDeleteModal}
        openModal={setShowDeleteModal}
        section={section}
      />
    </>
  );
};

export default Section;
