import { AiFillEdit } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import ProfileImage from '../../../utils/ProfileImage';
export const columns = [
  {
    Header: 'Action',
    Cell: ({ row }) => {
      return (
        <>
          <button className="btn btn-secondary align-middle btn-outline-green-1">
            <AiFillEdit />
          </button>
          <button className="btn btn-secondary align-middle btn-outline-green-1">
            <RiLockPasswordLine />
          </button>
        </>
      );
    },
  },
  {
    Header: 'ID',
    accessor: 'id',
  },

  {
    Header: 'Name',
    Cell: ({ row }) => {
      return (
        <>
          <ProfileImage pic={row.original.pic} />
          {row.original.user.first_name} {row.original.user.last_name}
        </>
      );
    },
  },

  {
    Header: 'Email',
    accessor: (d) => d.user.email,
  },
  {
    Header: 'Mobile',
    accessor: 'mobile',
  },
];
