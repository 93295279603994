export const TOPIC_LIST_SET_LIST = 'TOPIC_LIST_SET_LIST';
export const TOPIC_LIST_SET_TOPIC = 'TOPIC_LIST_SET_TOPIC';
export const TOPIC_LIST_UPDATE_TOPIC = 'TOPIC_LIST_UPDATE_TOPIC';
export const TOPIC_LIST_CREATE_TOPIC = 'TOPIC_LIST_CREATE_TOPIC';
export const TOPIC_LIST_DELETE_TOPIC = 'TOPIC_LIST_DELETE_TOPIC';

export const setTopics = (list) => {
  return {
    type: TOPIC_LIST_SET_LIST,
    payload: list,
  };
};

export const setTopic = (obj) => {
  return {
    type: TOPIC_LIST_SET_TOPIC,
    payload: obj,
  };
};

export const updateTopic = (obj) => {
  return {
    type: TOPIC_LIST_UPDATE_TOPIC,
    payload: obj,
  };
};

export const createTopic = (obj) => {
  return {
    type: TOPIC_LIST_CREATE_TOPIC,
    payload: obj,
  };
};

export const deleteTopic = (obj) => {
  return {
    type: TOPIC_LIST_DELETE_TOPIC,
    payload: obj,
  };
};
