import { Badge } from 'react-bootstrap';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SharingButton from './SharingButton';
import {
  SiDjango,
  SiPython,
  SiReact,
  SiJavascript,
  SiSwift,
} from 'react-icons/si';

const Article = ({ id, name, type, tags }) => {
  //paramters
  let history = useHistory();

  // HTML
  const showLogo = () => {
    if (type) {
      switch (type.name) {
        case 'Django':
          return <SiDjango className="card-header-logo" />;
        case 'ReactJS':
          return <SiReact className="card-header-logo" />;
        case 'Paython':
          return <SiPython className="card-header-logo" />;
        case 'JavaScript':
          return <SiJavascript className="card-header-logo" />;
        case 'React Native':
          return <SiReact className="card-header-logo" />;
        case 'Switft':
          return <SiSwift className="card-header-logo" />;
        default:
          return <SiDjango className="card-header-logo" />;
      }
    }
  };
  const setColor = () => {
    if (type) {
      switch (type.name) {
        case 'Django':
          return 'blue';
        case 'ReactJS':
          return 'orange';
        case 'Paython':
          return 'red';
        case 'JavaScript':
          return 'red';
        case 'React Native':
          return 'grey';
        case 'Switft':
          return 'grey';
        default:
          return 'grey';
      }
    }
  };

  //events
  const handleRedirect = (id) => {
    history.push(`/article/details/${id}`);
  };

  return (
    <div className="col-lg-6 mt-3">
      <div className={`card h-100 card-${setColor()} `}>
        <div className="card-header  ">
          <div className="row">
            <div className="col-6 ">
              <div className="mt-2">
                {showLogo()}
                <span className="ml-3 ">{type && type.name}</span>
              </div>
            </div>
            <div className="col-6 text-right">
              <SharingButton id={id} name={name} />
            </div>
          </div>
        </div>
        <div className="card-body  ">
          {tags &&
            tags.map((tag, index) => {
              return (
                <Badge key={index} bg="secondary">
                  {tag.name}
                </Badge>
              );
            })}
          <p className="mt-2" onClick={() => handleRedirect(id)}>
            <strong>{name}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Article;
