import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { RiShareLine } from 'react-icons/ri';
import CopyModal from '../../../../utils/CopyModal';
import WhatsappBtn from '../../../../utils/WhatsappBtn';
import CopyBtn from '../../../../utils/CopyBtn';
import PublicBtn from '../../../../utils/PublicBtn';

const SharingButton = ({ id, name, color }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const handleCloseModal = (e) => {
    setIsShowModal(false);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          className={`btn btn-outline-white-1 align-middle `}
          id="dropdown-basic"
        >
          <RiShareLine className="mr-1" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <WhatsappBtn id={id} name={name} />
          <CopyBtn id={id} name={name} setIsShowModal={setIsShowModal} />
          <PublicBtn id={id} />
        </Dropdown.Menu>
      </Dropdown>
      <CopyModal isShow={isShowModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default SharingButton;
