import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import RemoveModal from './remove/RemoveModal';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import * as actionsCreator from '../../../../utils/redux/topicListReducer.js/actions';
import { arraymove } from '../../../../utils/CustomFunctions';
import { instance } from '../../../../utils/CustomFunctions';

const Article = ({ section, article, index }) => {
  let profile = useSelector((state) => state.authReducer.profile);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const dispatch = useDispatch();
  const { topic } = useSelector((state) => state.topicListReducer);

  const getList = (list) => {
    return list.map((obj, index) => {
      return { id: obj.id, sequance: index };
    });
  };
  const postArray = async (list) => {
    await instance
      .post(`article/api/update/sequance/${topic.id}/`, list)
      .then((res) => dispatch(actionsCreator.setTopic(res.data.data)))
      .catch((err) => console.log(err));
  };
  const handleUp = () => {
    if (index !== 0) {
      const newSections = arraymove(section.articles, index, index - 1);
      const list = getList(newSections);
      postArray(list);
    }
  };
  const handleDown = () => {
    if (index !== section.articles.length - 1) {
      const newSections = arraymove(section.articles, index, index + 1);
      const list = getList(newSections);
      postArray(list);
    }
  };

  return (
    <>
      <div className="row mt-2" key={article.id}>
        <div className="col-8  my-auto">
          <h5>
            <Link
              to={`/article/public/${article.id}`}
              className="remove-link-decoration text-blue-1"
            >
              {index + 1} - {article.name}
            </Link>
          </h5>
        </div>
        {profile.user.is_superuser && (
          <div className="col-4 my-auto text-red-1 text-right">
            <button
              onClick={() => handleUp()}
              type="button"
              className="btn btn-outline-blue-1  btn-rounded"
            >
              <AiOutlineArrowUp />
            </button>
            <button
              onClick={() => handleDown(true)}
              type="button"
              className="btn btn-outline-blue-1  btn-rounded"
            >
              <AiOutlineArrowDown />
            </button>
            <button
              onClick={() => setShowRemoveModal(true)}
              type="button"
              className="btn btn-outline-blue-1  btn-rounded"
            >
              <FaTimes />
            </button>
          </div>
        )}
      </div>
      <RemoveModal
        isShow={showRemoveModal}
        openModal={setShowRemoveModal}
        article={article}
      />
    </>
  );
};

export default Article;
