import React from 'react';
import { Modal, Button } from 'react-bootstrap';
const CopyModal = ({ isShow, handleCloseModal }) => {
  return (
    <Modal show={isShow} onHide={handleCloseModal} centered>
      <Modal.Body>
        <div className="text-green-1">
          <strong>Linked got copied successfullys</strong>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-active-orange" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CopyModal;
