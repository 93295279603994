import React, { useState } from 'react';
import Title from './Title';
import Back from './Back';
import SubmitBtn from './SubmitBtn';
import { useDispatch, useSelector } from 'react-redux';
import { savePass } from '../../utils/redux/forgetPassReducer/actions';
import Loading from '../../utils/Loading';
import { useHistory } from 'react-router';

export const ResetPasswordForm = () => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { username, otp } = useSelector((state) => state.forgetPassReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === repeatPassword) {
      const obj = {
        username: username,
        otp: otp,
        password: password,
      };
      dispatch(savePass(obj, setLoading, setError, history));
    } else {
      setError('Passwords are not matching');
    }
  };

  return (
    <div className="container mt-5">
      <Back to="/forget-password" />
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6">
          <div className="card card-green">
            <Title title={'Add New Password'} />
            <div className="card-body">
              {error && (
                <div className="row text-center">
                  <div className="col-lg-12">
                    <div className="alert alert-danger">{error}</div>
                  </div>
                </div>
              )}
              {loading ? (
                <Loading />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="row  mt-2 ">
                    <div className="col-lg-12">
                      <div className="textOnInput ">
                        <label htmlFor="inputText " className="text-green-1">
                          New Password
                        </label>
                        <input
                          required={true}
                          type="password"
                          className="search-input input-overlay trans-input"
                          name="password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row  mt-2 ">
                    <div className="col-lg-12">
                      <div className="textOnInput ">
                        <label htmlFor="inputText " className="text-green-1">
                          Repeat Password
                        </label>
                        <input
                          required={true}
                          type="password"
                          className="search-input input-overlay trans-input"
                          name="repeatPassword"
                          id="repeatPassword"
                          value={repeatPassword}
                          onChange={(e) => setRepeatPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <SubmitBtn />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
