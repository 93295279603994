import React from 'react';
import { RiAddCircleFill } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import { useSelector, useDispatch } from 'react-redux';
import * as actionsCreator from '../../../../utils/redux/topicListReducer.js/actions';
import { arraymove } from '../../../../utils/CustomFunctions';
import { instance } from '../../../../utils/CustomFunctions';
import {
  AiFillDelete,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
} from 'react-icons/ai';

const SectionButtons = ({
  section,
  index,
  setShowAddArticle,
  setShowUpdateModal,
  setShowDeleteModal,
}) => {
  const profile = useSelector((state) => state.authReducer.profile);
  const dispatch = useDispatch();
  const { topic } = useSelector((state) => state.topicListReducer);

  const getList = (list) => {
    return list.map((obj, index) => {
      return { id: obj.id, sequance: index };
    });
  };
  const postArray = async (list) => {
    await instance
      .post(`article/api/section/update/sequance/${topic.id}/`, list)
      .then((res) => dispatch(actionsCreator.setTopic(res.data.data)))
      .catch((err) => console.log(err));
  };
  const handleUp = () => {
    if (index !== 0) {
      const newSections = arraymove(topic.sections, index, index - 1);
      const list = getList(newSections);
      postArray(list);
    }
  };
  const handleDown = () => {
    if (index !== topic.sections.length - 1) {
      const newSections = arraymove(topic.sections, index, index + 1);
      const list = getList(newSections);
      postArray(list);
    }
  };

  if (!profile.user.is_superuser) {
    return <></>;
  }

  return (
    <>
      <button
        onClick={() => handleUp()}
        type="button"
        className="btn btn-outline-green-1 my-auto"
      >
        <AiOutlineArrowUp />
      </button>
      <button
        onClick={() => handleDown(true)}
        type="button"
        className="btn btn-outline-green-1 my-auto"
      >
        <AiOutlineArrowDown />
      </button>
      <button
        onClick={() => setShowAddArticle(true)}
        type="button"
        className="btn btn-outline-green-1 my-auto"
      >
        <RiAddCircleFill />
      </button>
      <button
        onClick={() => setShowUpdateModal(true)}
        type="button"
        className="btn btn-outline-green-1 my-auto"
      >
        <BiEditAlt />
      </button>
      {section.articles.length <= 0 && (
        <button
          onClick={() => setShowDeleteModal(true)}
          type="button"
          className="btn btn-outline-red-1 my-auto"
        >
          <AiFillDelete />
        </button>
      )}
    </>
  );
};

export default SectionButtons;
