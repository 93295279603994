import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as actionsCreator from '../../../../../utils/redux/topicListReducer.js/actions';
import { useDispatch } from 'react-redux';
import { instance } from '../../../../../utils/CustomFunctions';

const DeleteModal = ({ isShow, openModal, section }) => {
  const dispatch = useDispatch();

  const handleDelete = async (e) => {
    await instance
      .delete(`article/api/section/delete/${section.id}/`)
      .then((res) => dispatch(actionsCreator.setTopic(res.data.data)))
      .then(() => openModal(false))
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={isShow} onHide={openModal} centered>
      <Modal.Body className="bg-dark-1">
        <div className="text-blue-1">
          <strong>Are you sure you want to continue?</strong>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-dark-1">
        <Button className="btn btn-secondary" onClick={() => openModal(false)}>
          Close
        </Button>
        <Button className="btn-active-red" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
