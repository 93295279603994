import React from 'react';

const NoAccess = () => {
  return (
    <section>
      <div className="container text-center mt-5">
        <div className="row">
          <div className="col-lg-12">
            <h4 className="text-red-1">
              <strong>Error! You do not have access to this page</strong>
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoAccess;
