import React from 'react';
import ProfileImage from '../../../utils/ProfileImage';

const Name = ({ pic, user, setIsShowImageModal, isShow }) => {
  return (
    <div className="col-8 ">
      <div className="mt-2">
        <div className="row ">
          <div className="col-3" onClick={(e) => setIsShowImageModal(!isShow)}>
            <ProfileImage pic={pic} />
          </div>
          <div className="col-9 my-auto">
            <strong className="ml-3 r">
              {user.first_name} {user.last_name}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Name;
