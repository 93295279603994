import React from 'react';
import { domainUrl } from './urls';
import { AiOutlineLink } from 'react-icons/ai';
import { Dropdown } from 'react-bootstrap';

const CopyBtn = ({ id, name, setIsShowModal }) => {
  const handleCopy = (e) => {
    var value = domainUrl + 'article/public/' + id;
    value = value + '\n' + name;
    navigator.clipboard.writeText(value);
    setIsShowModal(true);
  };
  return (
    <Dropdown.Item
      href="#"
      onClick={() => handleCopy(id)}
      className="text-green-1"
    >
      <AiOutlineLink className="margin-right" />
      Copy
    </Dropdown.Item>
  );
};

export default CopyBtn;
