import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Loading from '../../../utils/Loading';
import Error from '../../../utils/Error';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import SaveButton from './SaveButton';
import CancelButton from './CancelButton';
import SharingButton from '../../../utils/SharingButton';
import { validInput } from '../../../utils/CustomFunctions';
import { instance } from '../../../utils/CustomFunctions';
import BackButton from '../../../utils/BackButton';
import NameInput from '../article_create/form/NameInput';
import SelectInput from '../article_create/form/SelectInput';
import CheckBox from '../article_create/form/CheckBox';
import TextAreaInput from '../article_create/form/TextAreaInput';
import ConfirmationModal from './ConfirmationModal';
import { checkFormFields } from '../shared/customMethod';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actionCreaters from '../../../utils/redux/articleListReducer/actions';

const ArticleDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [markdown, setMarkdown] = useState('');
  const [isError, setIsError] = useState(false);
  const [types, setTypes] = useState([]);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  let history = useHistory();

  //Form
  const [name, setName] = useState('');
  const [type, setType] = useState(null);
  const [isTraining, setIsTraining] = useState(false);
  const [description, setDescription] = useState('');

  //validations
  const [nameValidation, setNameValidation] = useState(validInput);
  const [typeValidation, setTypeValidation] = useState(validInput);
  const [descValidation, setDescValidation] = useState(validInput);

  //API
  const getTypes = async () => {
    setIsLoading(true);
    await instance
      .get(`article/api/type/list/`)
      .then((res) => {
        const types = res.data.results.map((type) => {
          return {
            value: type.id,
            label: type.name,
          };
        });
        setTypes(types);
        getData();
      })
      .catch((err) => console.log(err));
  };
  const getData = async () => {
    setIsLoading(true);
    await instance
      .get(`article/api/details/${id}/`)
      .then((res) => {
        const data = res.data;
        setArticle(data);
        setName(data.name);
        setIsTraining(data.is_training);
        setDescription(data.html);
        setType({ value: data.type.id, label: data.type.name });
        setMarkdown(data.html);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const postData = async (obj, url) => {
    setIsLoading(true);
    await instance
      .post(url, obj)
      .then(() => setIsError(false))
      .then(() => setIsLoading(false))
      .catch((err) => console.log(err));
  };
  // UseEffects
  useEffect(() => {
    getTypes();
    // eslint-disable-next-line
  }, []);
  //Forms
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const isFormValid = checkFormFields(
      name,
      type,
      description,
      setNameValidation,
      setTypeValidation,
      setDescValidation
    );
    if (isFormValid) {
      const obj = {
        name: name,
        type: type.value,
        is_training: isTraining,
        html: description,
        updated_by: 1,
      };
      postData(obj, `article/api/update/${id}/`);
      setIsShowConfirmationModal(true);
    }
  };
  // Modal
  const handleDeleteModal = () => {
    setIsShowDeleteModal(!isShowDeleteModal);
  };
  // Actions
  const deleteArticle = async () => {
    await postData({}, `article/api/delete/${id}/`);
    dispatch(actionCreaters.deleteArticle([+id]));
    history.push('/');
  };

  if (isLoading) {
    return (
      <section className="mt-5">
        <Loading />
      </section>
    );
  }
  if (isError) {
    return <Error description={'API Failed, please check with admin'} />;
  }
  return (
    <>
      <form onSubmit={(e) => handleOnSubmit(e)}>
        <section>
          <div className="container mt-2">
            <BackButton url="/" />
            <div className="row no-gutters mt-3">
              <div className="col-lg-6">
                <NameInput
                  name={name}
                  setName={setName}
                  validation={nameValidation}
                  handleValidation={setNameValidation}
                />
                <SelectInput
                  types={types}
                  type={type}
                  setType={setType}
                  validation={typeValidation}
                  handleValidation={setTypeValidation}
                />
                <CheckBox
                  isTraining={isTraining}
                  setIsTraining={setIsTraining}
                />
              </div>
              <div className="col-lg-6 text-right mt-auto">
                <div className="row">
                  <div className="col-lg-12">
                    <SaveButton />
                  </div>
                  <div className="col-lg-12">
                    <CancelButton handleCancel={getData} />
                  </div>
                  <div className="col-lg-12">
                    <SharingButton {...article} />
                  </div>
                  <div className="col-lg-12">
                    <DeleteButton handleDeleteModal={handleDeleteModal} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr className="text-green-1" />
        <section>
          <div className="container mt-3 mb-5">
            <div className="row">
              <div className="col-lg-6">
                <TextAreaInput
                  description={description}
                  setDescription={setDescription}
                  setMarkdown={setMarkdown}
                  validation={descValidation}
                />
              </div>
              <div className="col-lg-6">
                <article className="result text-green-1 bordered mt-1">
                  <ReactMarkdown
                    children={markdown}
                    remarkPlugins={[remarkGfm]}
                  />
                </article>
              </div>
            </div>
          </div>
        </section>
      </form>

      <section>
        {isShowConfirmationModal && (
          <ConfirmationModal
            isShow={isShowConfirmationModal}
            setIsShow={setIsShowConfirmationModal}
          />
        )}
        {isShowDeleteModal && (
          <DeleteModal
            isShow={isShowDeleteModal}
            handleCancel={handleDeleteModal}
            handleProceed={deleteArticle}
          />
        )}
      </section>
    </>
  );
};

export default ArticleDetails;
