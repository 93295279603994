import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const ConfirmationModal = ({ isShow, setIsShow }) => {
  useEffect(() => {
    const event = setTimeout(() => {
      setIsShow(false);
    }, 2000);
    return () => {
      clearTimeout(event);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal show={isShow} centered>
      <Modal.Body className="bg-dark-1 text-center">
        <strong className="text-green-1">Transaction Done Successfuly</strong>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmationModal;
