import React, { useEffect, useState } from 'react';
import Topic from './Topic';
import Loading from '../../../utils/Loading';
import { IoIosAddCircle } from 'react-icons/io';
import CreateModal from '../create/CreateModal';
import SearchInput from '../../users/list/components/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import * as topicActionsCreator from '../../../utils/redux/topicListReducer.js/actions';
import * as userActionsCreator from '../../../utils/redux/userListReducer/actions';
import { instance } from '../../../utils/CustomFunctions';

const TopicList = () => {
  // state
  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  const profile = useSelector((state) => state.authReducer.profile);

  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, []);

  //redux
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.topicListReducer);
  const filterList = list.filter((topic) => {
    const lowerQuery = query.toLocaleLowerCase();
    return (
      topic.name.toLowerCase().includes(lowerQuery) ||
      topic.description.toLowerCase().includes(lowerQuery)
    );
  });

  //API
  const getUsers = async () => {
    await instance
      .get(`user-auth/api/list/`, {
        headers: {
          Authorization: `jwt ${token}`,
        },
      })
      .then((res) => dispatch(userActionsCreator.setUsers(res.data.results)))
      .then(() => setIsLoading(false))
      .catch((err) => console.log(err));
  };

  const fetchData = async () => {
    setIsLoading(true);
    await instance
      .get(`article/api/course/list/`, {
        headers: {
          Authorization: `jwt ${token}`,
        },
      })
      .then((res) => dispatch(topicActionsCreator.setTopics(res.data.results)))
      .then(() => getUsers())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchData();
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section>
        <div className="container mt-3">
          <div className="row no-gutters">
            <div className="col-6 col-lg-6">
              <SearchInput setQuery={setQuery} value={query} />
            </div>
            {profile.user.is_superuser && (
              <div className="col-6 col-lg-6 text-right">
                <button
                  className="btn btn-secondary align-middle btn-outline-green-1"
                  onClick={() => setIsShowCreateModal(true)}
                >
                  <IoIosAddCircle />
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      <hr className="text-green-1" />
      <section>
        <div className="container mt-3 mb-5">
          <div className="row">
            {!isLoading && filterList.length <= 0 ? (
              <div className="col-lg-12 text-center ">
                <h5 className="mt-5">
                  <strong className="text-red-1">
                    There is no more list match your query.
                  </strong>
                </h5>
              </div>
            ) : (
              <>
                {filterList.map((item, index) => {
                  return <Topic key={index} initialObj={item} />;
                })}
                {isLoading && <Loading />}
              </>
            )}
          </div>
        </div>
        <CreateModal
          isShow={isShowCreateModal}
          openModal={setIsShowCreateModal}
        />
      </section>
    </>
  );
};

export default TopicList;
