import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Title from './Title';
import Back from './Back';
import SubmitBtn from './SubmitBtn';
import Loading from '../../utils/Loading';
import { useDispatch } from 'react-redux';
import { saveUsername } from '../../utils/redux/forgetPassReducer/actions';

const ForgetPassword = () => {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(saveUsername(username));
    setLoading(false);
    history.push('/otp');
  };

  return (
    <div className="container mt-5">
      <Back to="/" />
      <div className="row">
        <div className="col-lg-3 " />
        <div className="col-lg-6 offset-col-4 ">
          <div className="card card-green">
            <Title title={'Forget Password'} />
            <div className="card-body">
              {loading ? (
                <Loading />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="row ">
                    <div className="col-lg-12">
                      <div className="textOnInput ">
                        <label htmlFor="inputText " className="text-green-1">
                          Email
                        </label>
                        <input
                          required={true}
                          type="text"
                          className="search-input input-overlay trans-input"
                          name="username"
                          id="username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <SubmitBtn />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
