export const USER_LIST_SET_LIST = 'USER_LIST_SET_LIST';
export const USER_LIST_UPDATE_USER = 'USER_LIST_UPDATE_USER';
export const USER_LIST_CREATE_USER = 'USER_LIST_CREATE_USER';

export const setUsers = (list) => {
  return {
    type: USER_LIST_SET_LIST,
    payload: list,
  };
};

export const updateUser = (obj) => {
  return {
    type: USER_LIST_UPDATE_USER,
    payload: obj,
  };
};

export const createUser = (obj) => {
  return {
    type: USER_LIST_CREATE_USER,
    payload: obj,
  };
};
