import React, { useEffect, useState } from 'react';
import { defaultCourseUrl } from '../../../utils/urls';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import DeleteModal from '../delete/DeleteModal';
import UpdateModal from '../update/UpdateModal';
import { Link } from 'react-router-dom';
import { AiOutlineOrderedList } from 'react-icons/ai';
import AccessModal from '../access/AccessModal';
import { useSelector } from 'react-redux';

const Topic = ({ initialObj }) => {
  const [topic, setTopic] = useState({
    id: initialObj.id,
    name: initialObj.name,
    description: initialObj.description,
    pic: initialObj.pic,
    access: initialObj.access,
  });
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const profile = useSelector((state) => state.authReducer.profile);
  useEffect(() => {
    setTopic({
      id: initialObj.id,
      name: initialObj.name,
      description: initialObj.description,
      pic: initialObj.pic,
      access: initialObj.access,
    });
  }, [initialObj]);

  return (
    <>
      <div className="col-lg-4 mt-3">
        <div className="card h-100 card-green">
          <Link to={`/topic/details/${topic.id}`}>
            <img
              src={topic.pic ? topic.pic : defaultCourseUrl}
              alt={topic.name}
              className="img-fluid w-100 rounded"
              style={{ height: '350px' }}
            />
          </Link>
          <div className="card-body ">
            <div className="row">
              <div className="col-lg-12">
                <strong>{topic.name}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p>{topic.description}</p>
              </div>
            </div>
          </div>
          {profile.user.is_superuser && (
            <div className="card-footer">
              <button
                className="btn btn-outline-green-1 mt-2"
                type="button"
                onClick={() => setIsShowUpdateModal(true)}
              >
                <AiFillEdit />
              </button>
              <button
                className="btn btn-outline-green-1 mt-2"
                type="button"
                onClick={() => setShowAccessModal(true)}
              >
                <AiOutlineOrderedList />
              </button>
              <button
                className="btn btn-outline-green-1 mt-2"
                type="button"
                onClick={() => setIsShowDeleteModal(true)}
              >
                <AiFillDelete />
              </button>
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        isShow={isShowDeleteModal}
        handleCancel={() => setIsShowDeleteModal(false)}
        initialObj={topic}
      />
      <UpdateModal
        isShow={isShowUpdateModal}
        handleCancel={() => setIsShowUpdateModal(false)}
        obj={{ ...topic }}
      />
      <AccessModal
        isShow={showAccessModal}
        handleCancel={() => setShowAccessModal(false)}
        initialObj={topic}
      />
    </>
  );
};

export default Topic;
