import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, ...rest }) => {
  let profile = useSelector((state) => state.authReducer.profile);
  return (
    <Route
      {...rest}
      render={() => {
        return profile ? children : <Redirect to="/login" />;
      }}
    ></Route>
  );
};

export default PrivateRoute;
