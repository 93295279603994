import { notValidInput } from '../../../utils/CustomFunctions';
import { isNullOrWhitespace, isNull } from '../../../utils/CustomFunctions';

export const checkFormFields = (
  name,
  type,
  description,
  setNameValidation,
  setTypeValidation,
  setDescValidation
) => {
  if (isNullOrWhitespace(name)) {
    setNameValidation(notValidInput);
    return false;
  }
  if (isNull(type)) {
    setTypeValidation(notValidInput);
    return false;
  }
  if (isNullOrWhitespace(description)) {
    setDescValidation(notValidInput);
    return false;
  }
  return true;
};

export const clearForm = (
  setName,
  setType,
  setIsTraining,
  setDescription,
  setMarkdown
) => {
  setName('');
  setType(null);
  setIsTraining(false);
  setDescription('');
  setMarkdown('');
};
