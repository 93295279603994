import { baseUrl } from './urls';
import axios from 'axios';
/**
 * To validate if the passed value is empty or not
 * @param {*} input test
 * @returns
 */
export const isNullOrWhitespace = (input) => {
  return typeof input === 'undefined' || !input || !input.trim();
};

export const isNull = (input) => {
  return typeof input === 'undefined' || !input;
};

export const validInput = {
  value: false,
  error: '',
};
export const notValidInput = {
  value: true,
  error: 'Field should not be empty',
};

export const axioHeader = (method, url, data) => {
  return {
    method: method,
    url: url,
    data: data,
  };
};

export const arraymove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

// axios
let headers = {};
if (localStorage.getItem('token')) {
  headers.Authorization = `jwt ${localStorage.getItem('token')}`;
}
export const instance = axios.create({
  baseURL: baseUrl,
  headers: headers,
});
