import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Loading from '../../../utils/Loading';
import * as actionsCreator from '../../../utils/redux/topicListReducer.js/actions';
import { useDispatch } from 'react-redux';
import { instance } from '../../../utils/CustomFunctions';

const CreateModal = ({ isShow, openModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [obj, setObj] = useState({
    name: '',
    description: '',
    pic: null,
  });

  //redux
  const dispatch = useDispatch();

  const postPic = async (id) => {
    const fd = new FormData();
    fd.append('pic', obj.pic);
    await instance
      .post(`article/api/course/pic/update/${id}/`, fd)
      .then((res) => dispatch(actionsCreator.updateTopic(res.data.data)))
      .then(() => openModal(false))
      .catch((err) => console.log(err));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await instance
      .post(`article/api/course/create/`, obj)
      .then((res) => dispatch(actionsCreator.createTopic(res.data.data)))
      .then((disResult) =>
        obj.pic ? postPic(disResult.payload.id) : setIsLoading(false)
      )
      .then(() => setObj({ name: '', description: '', pic: null }))
      .then(() => openModal(false))
      .catch((err) => console.log(err));
  };

  const handleOnChange = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
  };

  return (
    <Modal show={isShow} onHide={openModal} centered>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="bg-dark-1">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <label htmlFor="name" className="label-control text-green-1">
                    Name
                  </label>
                  <input
                    required={true}
                    type="text"
                    className="search-input"
                    name="name"
                    id="name"
                    value={obj.name}
                    onChange={(e) => handleOnChange(e)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12">
                  <label
                    htmlFor="description"
                    className="label-control text-green-1"
                  >
                    Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    rows="10"
                    className="w-100 form-control markdown-input mt-1"
                    value={obj.description}
                    onChange={(e) => handleOnChange(e)}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Form.Group
                    controlId="formFile"
                    className="mb-3 mt-2 btn-outline-green-1"
                  >
                    <Form.Label>Picture</Form.Label>
                    <Form.Control
                      type="file"
                      className="search-input"
                      onChange={(e) =>
                        setObj({ ...obj, pic: e.target.files[0] })
                      }
                    />
                  </Form.Group>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark-1">
          <Button
            className="btn btn-secondary"
            onClick={() => openModal(false)}
          >
            Close
          </Button>
          <Button className="btn-active-green" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateModal;
