import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './privateRoute/PrivateRoute';
import PrivateRouteAdminOnly from './privateRoute/PrivateRouteAdminOnly';
import NavigationBar from './components/navbar/NavigationBar';
import ArticleList from './components/article/article_list/ArticleList';
import ArticleDetails from './components/article/article_details/ArticleDetails';
import NoAccess from './utils/NoAccess';
import ArticlePublic from './components/article/article_public/ArticlePublic';
import ArticleCreate from './components/article/article_create/ArticleCreate';
import UserList from './components/users/list/UserList';
import TopicList from './components/topics/list/TopicList';
import ArticleTypeList from './components/article_type/list/ArticleTypeList';
import TopicDetails from './components/topics/details/TopicDetails';
import Login from './components/login/Login';
import ForgetPassword from './components/forgetPassword/ForgetPassword';
import { OtpForm } from './components/forgetPassword/OtpForm';
import { ResetPasswordForm } from './components/forgetPassword/ResetPasswordForm';
import Confirmation from './components/forgetPassword/Confirmation';

function App() {
  return (
    <Router>
      <NavigationBar />
      <Switch>
        <PrivateRouteAdminOnly exact path="/" children={<ArticleList />} />
        <Route exact path="/login" children={<Login />} />
        <PrivateRoute exact path="/user/list/" children={<UserList />} />
        <PrivateRoute exact path="/topic/list/" children={<TopicList />} />
        <PrivateRoute path="/topic/details/:id/" children={<TopicDetails />} />
        <PrivateRouteAdminOnly
          exact
          path="/type/list/"
          children={<ArticleTypeList />}
        />
        <PrivateRouteAdminOnly
          exact
          path="/article/create/"
          children={<ArticleCreate />}
        />
        <PrivateRouteAdminOnly
          exact
          path="/article/details/:id/"
          children={<ArticleDetails />}
        />
        <Route exact path="/article/public/:id/" children={<ArticlePublic />} />
        <Route exact path="/forget-password" children={<ForgetPassword />} />
        <Route exact path="/otp" children={<OtpForm />} />
        <Route exact path="/reset-password" children={<ResetPasswordForm />} />
        <Route exact path="/reset/confirmation" children={<Confirmation />} />
        <Route path="*" children={<NoAccess />} />
      </Switch>
    </Router>
  );
}

export default App;
