import React from 'react';
import { Form } from 'react-bootstrap';

const FileInput = ({ title, url, onChange, required }) => {
  return (
    <Form.Group controlId="formFile" className="mb-3 mt-2 btn-outline-green-1">
      <Form.Label>{title}</Form.Label>
      <Form.Control
        type="file"
        className="search-input"
        onChange={onChange}
        required={required}
      />
      {url && (
        <>
          <br />
          <a className="ml-3" href={url} target="_blank" rel="noreferrer">
            Current File ...
          </a>
        </>
      )}
    </Form.Group>
  );
};

export default FileInput;
