import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { TiArrowUpThick, TiArrowDownThick } from 'react-icons/ti';
import { useExportData } from 'react-table-plugins';
import { getExportFileBlob } from './getExportFileBlob';
import { GrDocumentCsv, GrDocumentPdf } from 'react-icons/gr';
const Table = ({ columns, data }) => {
  const columnsObj = useMemo(() => columns, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    exportData,
    prepareRow,
  } = useTable(
    {
      columns: columnsObj,
      data: data,
      getExportFileBlob,
    },
    useSortBy,
    useExportData
  );

  return (
    <>
      <div className="row mb-2 no-gutters">
        <div className="col-6 col-lg-2">
          <button
            className="btn btn-secondary align-middle btn-outline-green-1 w-100"
            onClick={() => {
              exportData('csv', true);
            }}
          >
            {' '}
            <GrDocumentCsv className="mr-2 bg-green-1 " /> Export CSV
          </button>
        </div>
        <div className="col-6 col-lg-2">
          <button
            className="btn btn-secondary align-middle btn-outline-green-1 w-100"
            onClick={() => {
              exportData('pdf', true);
            }}
          >
            <GrDocumentPdf className="mr-2 bg-green-1 " /> Export PDF
          </button>{' '}
        </div>
      </div>
      <div className="table-responsive">
        <table
          {...getTableProps()}
          className="table table-bordered text-blue-1"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="bg-green-2 text-dark-1 text-center"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <strong>
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TiArrowDownThick />
                        ) : (
                          <TiArrowUpThick />
                        )
                      ) : (
                        ''
                      )}
                    </strong>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        <strong>{cell.render('Cell')}</strong>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default Table;
