import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { login, setToken } from '../../utils/redux/authReducer/actions';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Login = () => {
  const profile = useSelector((state) => state.authReducer.profile);
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState({
    isError: false,
    message: '',
  });
  const [obj, setObj] = useState({
    username: '',
    password: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(obj, setError, history));
  };
  const handleChange = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
  };

  if (profile) {
    return <Redirect to="/" />;
  }

  dispatch(setToken());

  return (
    <div className="container d-flex justify-content-center align-items-center full-height ">
      <div className="row ">
        <div className="col-lg-12">
          <div className="card card-green minw-400">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row text-center text-green-1 mb-4 mt-4">
                  <div className="col-lg-12">
                    <h1>WAREHOUSE</h1>
                  </div>
                </div>
                {error.isError && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="alert alert-danger">{error.message}</div>
                    </div>
                  </div>
                )}
                <div className="row ">
                  <div className="col-lg-12">
                    <div className="textOnInput ">
                      <label htmlFor="inputText " className="text-green-1">
                        Username
                      </label>
                      <input
                        required={true}
                        type="text"
                        className="search-input input-overlay trans-input"
                        name="username"
                        id="username"
                        value={obj.username}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <div className="textOnInput ">
                      <label htmlFor="inputText " className="text-green-1">
                        Password
                      </label>
                      <input
                        required={true}
                        type="password"
                        className="search-input input-overlay"
                        name="password"
                        id="password"
                        value={obj.password}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-12">
                    <button
                      className="btn btn-active-green form-control"
                      type="submit"
                    >
                      LOGIN
                    </button>
                  </div>
                </div>

                <div className="row text-center mt-2 mb-5">
                  <div className="col-lg-12">
                    <p className="text-green-1">
                      <Link
                        to="/forget-password"
                        className="remove-link-decoration text-green-1"
                      >
                        Forget Password?
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
