import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Loading from '../../utils/Loading';
import Input from '../users/form/Input';
import { instance } from '../../utils/CustomFunctions';
import { useSelector } from 'react-redux';

const ChangePasswordModal = ({ isShow, openModal }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const profile = useSelector((state) => state.authReducer.profile);

  const clearForm = () => {
    setOldPassword('');
    setNewPassword('');
  };
  const validateForm = () => {
    return oldPassword === newPassword;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      setIsLoading(true);
      await instance
        .post(`user-auth/api/change-password/${profile.user.id}/`, {
          new_password: newPassword,
        })
        .then(() => setIsLoading(false))
        .then(() => clearForm())
        .then(() => openModal(false))
        .catch((err) => console.log(err.response));
    } else {
      setErrorMessage('Passwords are not matching');
    }
  };

  return (
    <Modal show={isShow} onHide={openModal} centered>
      <form onSubmit={handleSubmit}>
        {isLoading ? (
          <Modal.Body className="bg-dark-1">
            <Loading />
          </Modal.Body>
        ) : (
          <Modal.Body className="bg-dark-1">
            {errorMessage && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="alert alert-danger">{errorMessage}</div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-12">
                <>
                  <Input
                    name="oldPassword"
                    label="Old Password"
                    required={true}
                    placeholder=""
                    type="password"
                    value={oldPassword}
                    setValue={setOldPassword}
                  />
                  <Input
                    name="newPassword"
                    label="New Password"
                    required={true}
                    placeholder=""
                    type="password"
                    value={newPassword}
                    setValue={setNewPassword}
                  />
                </>
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer className="bg-dark-1">
          <Button
            className="btn btn-secondary"
            onClick={() => openModal(false)}
          >
            Close
          </Button>
          <Button className="btn btn-active-green" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default ChangePasswordModal;
