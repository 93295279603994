import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Container, Col } from 'react-bootstrap/';

const Loading = () => {
  return (
    <Container className=" text-center justify-content-center">
      <div className="row">
        <Col>
          <Spinner
            animation="border"
            variant="success"
            size="xl"
            className="spinner-large"
          />
        </Col>
      </div>
    </Container>
  );
};

export default Loading;
