import React from 'react';
import { Form } from 'react-bootstrap';

const CheckBox = ({ isTraining, setIsTraining }) => {
  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Form.Group className="mb-3 text-blue-3 " controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="Show for trainee?"
            tabIndex={3}
            defaultChecked={isTraining}
            onChange={(e) => setIsTraining(e.target.checked)}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export default CheckBox;
