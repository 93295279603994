import React from 'react';
import { BsTable, BsCardText } from 'react-icons/bs';

const LayoutButtons = ({ isCardLayout, handleLayout }) => {
  return (
    <>
      <button
        className={`btn btn-secondary align-middle ${
          isCardLayout ? 'btn-active-green' : 'btn-outline-green-1'
        }`}
        onClick={() => handleLayout()}
      >
        <BsCardText />
      </button>
      <button
        className={`btn btn-secondary align-middle ${
          !isCardLayout ? 'btn-active-green' : 'btn-outline-green-1'
        }`}
        onClick={() => handleLayout()}
      >
        <BsTable />
      </button>
    </>
  );
};

export default LayoutButtons;
