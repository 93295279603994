import { AUTH_SET_USER, AUTH_LOGOUT } from './actions';

const initialState = {
  profile: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_USER:
      return {
        ...state,
        profile: action.payload,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        profile: null,
      };
    default:
      return state;
  }
};
