import { instance } from '../../CustomFunctions';
import decode from 'jwt-decode';

export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const login = (obj, setError, history) => {
  return async (dispatch) => {
    try {
      const res = await instance.post(`user-auth/api/login/`, obj);
      await dispatch(setToken(res.data.token));
      const profile = res.data.data;
      profile.user.is_superuser
        ? history.replace('/')
        : history.replace('/topic/list/');
    } catch (err) {
      if (err && err.response && err.response.data) {
        setError({ isError: true, message: err.response.data.message });
      } else {
        console.log(err);
      }
    }
  };
};

export const updateMyProfile = (obj, id) => {
  return async (dispatch) => {
    await instance
      .post(`user-auth/api/update/my-profile/${id}/`, obj)
      .then((res) => dispatch(setToken(res.data.token)))
      .catch((err) => console.log(err));
  };
};

export const logout = (history) => {
  history.replace('/login');
  return setToken();
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    return { type: AUTH_SET_USER, payload: decode(token) };
  } else {
    localStorage.removeItem('token');
    return { type: AUTH_LOGOUT };
  }
};

export const isTokenValid = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const currentTime = Date.now();
    const user = decode(token);
    if (user.exp * 1000 > currentTime) {
      return setToken(token);
    }
  }
  return setToken();
};

// navbar start to ignore the conditions and get displayed at login page
// what to do with user privliiges if i have alot ?
// how to use refresh_token ?
