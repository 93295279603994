import React from 'react';
import { AiOutlineSave } from 'react-icons/ai';

const SaveButton = () => {
  return (
    <button tabIndex={5} className="btn form-control btn-active-green">
      <AiOutlineSave className="margin-right" />
      <strong>Save</strong>
    </button>
  );
};

export default SaveButton;
