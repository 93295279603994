import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import EmailInput from '../form/Emailnput';
import FistNameInput from '../form/FistNameInput';
import LastNameInput from '../form/LastNameInput';
import MobileInput from '../form/MobileInput';
import { modalHeaderStyle } from '../../../utils/Style';
import { validInput } from '../../../utils/CustomFunctions';
import { instance } from '../../../utils/CustomFunctions';
import { checkFormFields } from '../shared/customMethod';
import Loading from '../../../utils/Loading';
import { useDispatch } from 'react-redux';
import * as actionCreaters from '../../../utils/redux/userListReducer/actions';

const UpdateModal = ({ obj, isShow, handleCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  //Form
  const [firstName, setFirstName] = useState(obj.user.first_name);
  const [lastName, setLastName] = useState(obj.user.last_name);
  const [mobile, setMobile] = useState(obj.mobile);
  const [email, setEmail] = useState(obj.user.email);
  const [isTrainee, setIsTrainee] = useState(obj.is_trainee);

  //validations
  const [firstNameValidation, setFirstNameValidation] = useState(validInput);
  const [lastNameValidation, setLastNameValidation] = useState(validInput);
  const [emailValidation, setEmailValidation] = useState(validInput);
  const [mobileValidation, setMobileValidation] = useState(validInput);

  //redux
  const dispatch = useDispatch();
  const updateObj = (obj) => {
    dispatch(actionCreaters.updateUser(obj));
  };

  // API
  const postData = async (obj, url) => {
    setIsLoading(true);
    await instance
      .post(url, obj)
      .then((res) => updateObj(res.data.data))
      .then(() => setIsLoading(false))
      .then(() => handleCancel())
      .catch((err) => console.log(err));
  };

  // Form Action
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const isFormValid = checkFormFields(
      firstName,
      lastName,
      mobile,
      email,
      setFirstNameValidation,
      setLastNameValidation,
      setEmailValidation,
      setMobileValidation
    );
    if (isFormValid) {
      const newObj = {
        id: obj.id,
        first_name: firstName,
        last_name: lastName,
        email: email,
        mobile: mobile,
        is_trainee: isTrainee,
      };
      postData(newObj, `user-auth/api/update/${obj.id}/`);
    }
  };

  useEffect(() => {
    setFirstName(obj.user.first_name);
    setLastName(obj.user.last_name);
    setMobile(obj.mobile);
    setEmail(obj.user.email);
    setIsTrainee(obj.is_trainee);
  }, [obj]);

  return (
    <Modal show={isShow} onHide={handleCancel} centered>
      <Modal.Header
        className="text-center bg-dark-1 text-green-1 text-uppercase"
        style={modalHeaderStyle}
      >
        <strong>Update Profile</strong>
      </Modal.Header>
      {isLoading ? (
        <Modal.Body className="bg-dark-1">
          <Loading />
        </Modal.Body>
      ) : (
        <form onSubmit={handleOnSubmit}>
          <Modal.Body className="bg-dark-1">
            <FistNameInput
              validation={firstNameValidation}
              handleValidation={setFirstNameValidation}
              setValue={setFirstName}
              value={firstName}
            />
            <LastNameInput
              setValue={setLastName}
              value={lastName}
              validation={lastNameValidation}
              handleValidation={setLastNameValidation}
            />
            <EmailInput
              setValue={setEmail}
              value={email}
              validation={emailValidation}
              handleValidation={setEmailValidation}
            />
            <MobileInput
              setValue={setMobile}
              value={mobile}
              validation={mobileValidation}
              handleValidation={setMobileValidation}
            />
            <div className="row mt-3">
              <div className="col-lg-12">
                <Form.Check
                  type="checkbox"
                  defaultChecked={isTrainee}
                  onClick={(e) => setIsTrainee(!isTrainee)}
                  id="isClearPic"
                  label="Is Trainee ?"
                  className="text-blue-1"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-dark-1 ">
            <Button className="btn btn-secondary" onClick={handleCancel}>
              Close
            </Button>
            <Button className="btn btn-active-green" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};
export default UpdateModal;
