import React from 'react';

const MobileInput = ({ setValue, value, validation, handleValidation }) => {
  const handleOnChange = (e) => {
    setValue(e.target.value);
    handleValidation(false);
  };
  return (
    <div className="row mt-2">
      <div className="col-lg-12">
        <label className="mt-2 text-green-1" htmlFor="mobile">
          Mobile
        </label>
        <input
          type="mobile"
          className="form-control markdown-input mt-1"
          placeholder="Last Name ..."
          name="mobile"
          id="mobile"
          value={value}
          onChange={(e) => handleOnChange(e)}
          tabIndex={3}
        />
        {validation.value && (
          <span className="text-red-1">{validation.error}</span>
        )}
      </div>
    </div>
  );
};

export default MobileInput;
