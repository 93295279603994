import React from 'react';

const SubmitBtn = () => {
  return (
    <div className="row  mt-3">
      <div className="col-lg-12">
        <button className="btn btn-active-green form-control" type="submit">
          Save
        </button>
      </div>
    </div>
  );
};

export default SubmitBtn;
