import React from 'react';
import { Badge } from 'react-bootstrap';
import { badgeStyle } from '../../../utils/Style';

const UserInfo = ({ user, mobile, is_trainee }) => {
  return (
    <>
      <div className="row">
        <div className="col-3">Email</div>
        <div className="col-9">
          <strong>{user.email}</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-3">Mobile</div>
        <div className="col-9">
          <strong>{mobile}</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-3">Tags</div>
        <div className="col-9">
          {is_trainee && (
            <Badge bg="secondary" style={badgeStyle}>
              Trainee
            </Badge>
          )}
          {user.is_staff && (
            <Badge
              bg="secondary "
              className="bg-active-orange"
              style={badgeStyle}
            >
              Staff
            </Badge>
          )}
          {user.is_superuser && (
            <Badge bg="secondary" style={badgeStyle}>
              Admin
            </Badge>
          )}
        </div>
      </div>
    </>
  );
};

export default UserInfo;
