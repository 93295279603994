import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import BackButton from './BackButton';
import NameInput from './form/NameInput';
import SaveButton from './SaveButton';
import CancelButton from './CancelButton';
import Loading from '../../../utils/Loading';
import CheckBox from './form/CheckBox';
import SelectInput from './form/SelectInput';
import TextAreaInput from './form/TextAreaInput';
import ConfirmationModal from './ConfirmationModal';
import { validInput } from '../../../utils/CustomFunctions';
import { checkFormFields, clearForm } from '../shared/customMethod';
import { instance } from '../../../utils/CustomFunctions';

const ArticleCreate = () => {
  //values
  const [types, setTypes] = useState([]);
  const [markdown, setMarkdown] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [responseID, setResponseID] = useState(0);

  //Form
  const [name, setName] = useState('');
  const [type, setType] = useState(null);
  const [isTraining, setIsTraining] = useState(false);
  const [description, setDescription] = useState('');

  //validations
  const [nameValidation, setNameValidation] = useState(validInput);
  const [typeValidation, setTypeValidation] = useState(validInput);
  const [descValidation, setDescValidation] = useState(validInput);

  // API

  const postData = async (obj, url) => {
    setIsLoading(true);
    try {
      const response = await instance.post(url, obj);
      setResponseID(response.data.id);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await instance.get('article/api/type/list/');
        const types = response.data.results.map((type) => {
          return {
            value: type.id,
            label: type.name,
          };
        });
        setTypes(types);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Form Action
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const isFormValid = checkFormFields(
      name,
      type,
      description,
      setNameValidation,
      setTypeValidation,
      setDescValidation
    );
    if (isFormValid) {
      const obj = {
        name: name,
        type: type.value,
        is_training: isTraining,
        html: description,
        created_by: 1,
      };
      postData(obj, 'article/api/create/');
      clearForm(setName, setType, setIsTraining, setDescription, setMarkdown);
      setIsShowConfirmationModal(true);
    }
  };

  if (isLoading) {
    return (
      <div className="conatiner mt-5">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <form onSubmit={(e) => handleOnSubmit(e)}>
        <section>
          <div className="container mt-2">
            <BackButton />
            <div className="row no-gutters mt-3">
              <div className="col-lg-6">
                <NameInput
                  name={name}
                  setName={setName}
                  validation={nameValidation}
                  handleValidation={setNameValidation}
                />
                <SelectInput
                  types={types}
                  type={type}
                  setType={setType}
                  validation={typeValidation}
                  handleValidation={setTypeValidation}
                />
                <CheckBox
                  isTraining={isTraining}
                  setIsTraining={setIsTraining}
                />
              </div>
              <SaveButton />
              <CancelButton />
            </div>
          </div>
        </section>
        <hr className="text-green-1" />
        <section>
          <div className="container mt-3 mb-5">
            <div className="row">
              <div className="col-lg-6">
                <TextAreaInput
                  description={description}
                  setDescription={setDescription}
                  setMarkdown={setMarkdown}
                  validation={descValidation}
                />
              </div>
              <div className="col-lg-6">
                <article className="result text-green-1 bordered mt-1">
                  <ReactMarkdown
                    children={markdown}
                    remarkPlugins={[remarkGfm]}
                  />
                </article>
              </div>
            </div>
          </div>
        </section>
      </form>

      <section>
        {isShowConfirmationModal && (
          <ConfirmationModal
            isShow={isShowConfirmationModal}
            setIsShow={setIsShowConfirmationModal}
            id={responseID}
          />
        )}
      </section>
    </>
  );
};

export default ArticleCreate;
