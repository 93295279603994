import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { modalHeaderStyle } from '../../../utils/Style';
import Input from '../form/Input';
import { isNullOrWhitespace } from '../../../utils/CustomFunctions';
import { instance } from '../../../utils/CustomFunctions';
import Loading from '../../../utils/Loading';
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../../utils/redux/userListReducer/actions';

const CreateModal = ({ isShow, setIsShowCreateModal }) => {
  // redux
  const dispatch = useDispatch();

  //Form
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isTrainee, setIsTrainee] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkForm = () => {
    return (
      !isNullOrWhitespace(firstName) &&
      !isNullOrWhitespace(lastName) &&
      !isNullOrWhitespace(email) &&
      !isNullOrWhitespace(mobile) &&
      !isNullOrWhitespace(password)
    );
  };

  const createObject = () => {
    return {
      first_name: firstName,
      last_name: lastName,
      mobile: mobile,
      email: email,
      password: password,
      is_trainee: isTrainee,
    };
  };
  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMobile('');
    setPassword('');
    setIsTrainee(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = checkForm();
    if (isFormValid) {
      const obj = createObject();
      postObject(obj);
    }
  };
  const postObject = async (obj) => {
    setIsLoading(true);
    await instance
      .post(`user-auth/api/create/`, obj)
      .then((res) => dispatch(actionCreators.createUser(res.data.data)))
      .then(() => setIsLoading())
      .then(() => clearForm())
      .then(() => setIsShowCreateModal())
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={isShow} onHide={() => setIsShowCreateModal(false)} centered>
      <Modal.Header
        className="text-center bg-dark-1 text-green-1 text-uppercase"
        style={modalHeaderStyle}
      >
        <strong>Create New User</strong>
      </Modal.Header>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Modal.Body className="bg-dark-1">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Input
                name="firstName"
                label="First Name"
                required={true}
                placeholder=""
                type="text"
                value={firstName}
                setValue={setFirstName}
              />
              <Input
                name="lastName"
                label="Last Name"
                required={true}
                placeholder=""
                type="text"
                value={lastName}
                setValue={setLastName}
              />
              <Input
                name="mobile"
                label="Mobile"
                placeholder=""
                required={true}
                type="text"
                value={mobile}
                setValue={setMobile}
              />
              <Input
                name="email"
                label="Email"
                placeholder=""
                required={true}
                type="email"
                value={email}
                setValue={setEmail}
              />
              <Input
                name="password"
                label="Password"
                required={true}
                placeholder=""
                type="password"
                value={password}
                setValue={setPassword}
              />

              <div className="row mt-3">
                <div className="col-lg-12">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={isTrainee}
                    onClick={(e) => setIsTrainee(!isTrainee)}
                    id="isClearPic"
                    label="Is Trainee ?"
                    className="text-blue-1"
                  />
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark-1 ">
          <Button
            className="btn btn-secondary"
            onClick={() => setIsShowCreateModal(false)}
          >
            Close
          </Button>
          <Button className="btn btn-active-green" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default CreateModal;
