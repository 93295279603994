import React from 'react';
import { defaultImageUrl } from '../utils/urls';

const ProfileImage = ({ pic }) => {
  return (
    <img src={pic ? pic : defaultImageUrl} className="img-profile" alt="" />
  );
};

export default ProfileImage;
