export const ARTICLE_TYPE_LIST_SET_LIST = 'ARTICLE_TYPE_LIST_SET_LIST';
export const ARTICLE_TYPE_LIST_UPDATE_ARTICLE_TYPE =
  'ARTICLE_TYPE_LIST_UPDATE_ARTICLE_TYPE';
export const ARTICLE_TYPE_LIST_CREATE_ARTICLE_TYPE =
  'ARTICLE_TYPE_LIST_CREATE_ARTICLE_TYPE';
export const ARTICLE_TYPE_LIST_DELETE_ARTICLE_TYPE =
  'ARTICLE_TYPE_LIST_DELETE_ARTICLE_TYPE';

export const setList = (list) => {
  return {
    type: ARTICLE_TYPE_LIST_SET_LIST,
    payload: list,
  };
};

export const createObj = (obj) => {
  return {
    type: ARTICLE_TYPE_LIST_CREATE_ARTICLE_TYPE,
    payload: obj,
  };
};

export const updateObj = (obj) => {
  return {
    type: ARTICLE_TYPE_LIST_UPDATE_ARTICLE_TYPE,
    payload: obj,
  };
};

export const deleteObj = (obj) => {
  return {
    type: ARTICLE_TYPE_LIST_DELETE_ARTICLE_TYPE,
    payload: obj,
  };
};
