import { isNull, notValidInput } from '../../../utils/CustomFunctions';
import { isNullOrWhitespace } from '../../../utils/CustomFunctions';

// Update User Info Form
export const checkFormFields = (
  firstName,
  lastName,
  mobile,
  email,
  setFirstNameValidation,
  setLastNameValidation,
  setEmailValidation,
  setMobileValidation
) => {
  if (isNullOrWhitespace(firstName)) {
    setFirstNameValidation(notValidInput);
    return false;
  }
  if (isNullOrWhitespace(lastName)) {
    setLastNameValidation(notValidInput);
    return false;
  }
  if (isNullOrWhitespace(email)) {
    setEmailValidation(notValidInput);
    return false;
  }
  if (isNullOrWhitespace(mobile)) {
    setMobileValidation(notValidInput);
    return false;
  }
  return true;
};
export const clearForm = (setFirstName, setLastName, setEmail, setMobile) => {
  setFirstName('');
  setLastName('');
  setEmail('');
  setMobile('');
};

// ImageForm
export const checkImageForm = (file, setFileValidation) => {
  if (isNull(file)) {
    setFileValidation(notValidInput);
    return false;
  }
  return true;
};
export const clearImageForm = (setPic) => {
  setPic('');
};
