import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as actionsCreator from '../../../utils/redux/articleTypeReducer/actions';
import { useDispatch } from 'react-redux';
import { instance } from '../../../utils/CustomFunctions';

const DeleteModal = ({ isShow, handleCloseModal, initialObj }) => {
  const dispatch = useDispatch();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    await instance
      .delete(`article/api/type/delete/${initialObj.id}/`)
      .then(() => dispatch(actionsCreator.deleteObj(initialObj.id)))
      .then(() => handleCloseModal(false))
      .catch((err) => console.log(err.response));
  };

  return (
    <Modal show={isShow} onHide={handleCloseModal} centered>
      <form onSubmit={handleOnSubmit}>
        <Modal.Body className="bg-dark-1">
          <div className="row">
            <div className="col-lg-12">
              <p className="text-green-1">
                Are you sure you want to delete this record ?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-outline-green-1"
            onClick={() => handleCloseModal(false)}
          >
            Close
          </Button>
          <Button className="btn btn-active-green" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default DeleteModal;
