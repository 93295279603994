import {
  ARTICLE_TYPE_LIST_SET_LIST,
  ARTICLE_TYPE_LIST_UPDATE_ARTICLE_TYPE,
  ARTICLE_TYPE_LIST_CREATE_ARTICLE_TYPE,
  ARTICLE_TYPE_LIST_DELETE_ARTICLE_TYPE,
} from './actions';

const initialState = {
  list: [],
};

export const articleTypeReducer = (state = initialState, action) => {
  let newList;
  switch (action.type) {
    case ARTICLE_TYPE_LIST_SET_LIST:
      return { ...state, list: sorting(action.payload) };
    case ARTICLE_TYPE_LIST_CREATE_ARTICLE_TYPE:
      newList = [...state.list, action.payload];
      return {
        ...state,
        list: sorting(newList),
      };
    case ARTICLE_TYPE_LIST_UPDATE_ARTICLE_TYPE:
      const newObj = action.payload;
      newList = state.list.map((obj) => {
        if (obj.id === newObj.id) return newObj;
        else return obj;
      });
      return {
        ...state,
        list: sorting(newList),
      };
    case ARTICLE_TYPE_LIST_DELETE_ARTICLE_TYPE:
      return {
        ...state,
        list: state.list.filter((obj) => obj.id !== action.payload),
      };
    default:
      return state;
  }
};

const sorting = (list) => {
  return list.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return +1;
    if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
    return 0;
  });
};
