import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Card from './Card';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { selectCustomStyle } from '../../../utils/SelectCustomStyle';
import { useDispatch } from 'react-redux';
import * as actionsCreator from '../../../utils/redux/topicListReducer.js/actions';
import { instance } from '../../../utils/CustomFunctions';

const AccessModal = ({ initialObj, isShow, handleCancel }) => {
  //state
  const [value, setValue] = useState(null);
  const [obj, setObj] = useState({
    id: initialObj.id,
    list: initialObj.access,
  });

  //Redux
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.userListReducer);
  const users = list.map((profile) => {
    return {
      value: profile.user.id,
      label: `${profile.user.first_name} ${profile.user.last_name}`,
    };
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setObj({
        id: initialObj.id,
        list: initialObj.access,
      });
    }
    return () => (mounted = false);
  }, [initialObj]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    await instance
      .post(
        `article/api/access/create/${obj.id}/`,
        value.map((select) => select.value)
      )
      .then((res) => dispatch(actionsCreator.updateTopic(res.data.data)))
      .then(() => setValue(null))
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={isShow} onHide={handleCancel} size="lg">
      <Modal.Header className="justify-content-center bg-dark-1 text-green-1">
        <Modal.Title className="text-center">ACCESS MANGEMENT</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark-1">
        <form onSubmit={(e) => handleOnSubmit(e)}>
          <div className="row">
            <div className="col-lg-9">
              <Select
                isMulti
                options={users}
                className="markdown-input"
                styles={selectCustomStyle}
                onChange={(e) => setValue(e)}
                value={value}
              />
            </div>
            <div className="col-lg-3">
              <button
                className="btn form-control btn-active-green"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
        <hr className="text-grey-1" />
        <div className="row">
          <div className="col-lg-12">
            {obj.list.map((access, index) => {
              return <Card key={index} access={access} />;
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-dark-1">
        <Button className="btn btn-secondary" onClick={handleCancel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessModal;
