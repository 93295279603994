import React from 'react';
import { AiOutlineSave } from 'react-icons/ai';

const SaveButton = () => {
  return (
    <div className="offset-lg-2 col-lg-2 text-right mt-auto">
      <button className="btn form-control btn-active-green" tabIndex={5}>
        <AiOutlineSave className="margin-right" />
        Save
      </button>
    </div>
  );
};

export default SaveButton;
