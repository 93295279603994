import React from 'react';
import { BiEditAlt, BiTrash } from 'react-icons/bi';
import { RiMailAddFill } from 'react-icons/ri';

const CourseButtons = ({
  setShowUpdateModal,
  setIsShowCreateModal,
  setShowDeleteModal,
}) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-outline-green-1"
        onClick={() => setIsShowCreateModal(true)}
      >
        <RiMailAddFill className="margin-right" />
        Add Sections
      </button>
      <button
        type="button"
        className="btn btn-outline-green-1"
        onClick={() => setShowUpdateModal(true)}
      >
        <BiEditAlt />
      </button>

      {/* {sections.length <= 0 && ( */}
      <button
        type="button"
        className="btn btn-outline-green-1"
        onClick={() => setShowDeleteModal(true)}
      >
        <BiTrash />
      </button>
      {/* )} */}
    </>
  );
};

export default CourseButtons;
