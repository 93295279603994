import { Link } from 'react-router-dom';
import SharingButton from './SharingButton';

export const columns = [
  {
    Header: 'Action',
    Cell: ({ row }) => {
      return (
        <SharingButton
          id={row.values.id}
          name={row.values.name}
          color="green"
        />
      );
    },
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Type',
    accessor: (d) => d.type.name,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row }) => {
      return (
        <Link
          className="remove-link-decoration text-blue-1"
          to={`article/details/${row.values.id}`}
        >
          {row.values.name}
        </Link>
      );
    },
  },
];
