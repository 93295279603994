import React, { useState } from 'react';
import { isNullOrWhitespace } from '../../../utils/CustomFunctions';

const Input = ({
  name,
  label,
  required,
  type,
  placeholder,
  value,
  setValue,
  tableIndex,
}) => {
  const [firstUse, setFirstUse] = useState(true); // to avoid showing validation error at initiation

  const handleOnChange = (e) => {
    setFirstUse(false);
    setValue(e.target.value);
  };

  return (
    <div className="row mt-2">
      <div className="col-lg-12">
        <label className="mt-2 text-green-1" htmlFor={name}>
          {label}
        </label>
        <input
          placeholder={placeholder}
          required={required}
          type={type}
          className="form-control markdown-input mt-1"
          name={name}
          id={name}
          value={value}
          onChange={(e) => handleOnChange(e)}
          tabIndex={tableIndex}
        />
        {isNullOrWhitespace(value) && !firstUse && (
          <span className="text-red-1">Field is mandatory</span>
        )}
      </div>
    </div>
  );
};

export default Input;
