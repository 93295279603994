import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { selectCustomStyle } from '../../../../../utils/SelectCustomStyle';
import { setTopic } from '../../../../../utils/redux/topicListReducer.js/actions';
import { deleteArticle } from '../../../../../utils/redux/articleListReducer/actions';
import { instance } from '../../../../../utils/CustomFunctions';

const AddModal = ({ isShow, openModal, section }) => {
  //state
  const [value, setValue] = useState(null);

  //redux
  const dispatch = useDispatch();
  const { articles } = useSelector((state) => state.articleListReducer);
  const options = articles.map((article) => {
    return {
      value: article.id,
      label: article.name,
    };
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (value) {
      const ids = value.map((select) => select.value);
      dispatch(deleteArticle(ids));
      await instance
        .post(`article/api/add-to-section/${section.id}/`, ids)
        .then((res) => dispatch(setTopic(res.data.data)))
        .then(() => setValue(null))
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal show={isShow} onHide={openModal} centered size="lg">
      <Modal.Header className="justify-content-center bg-dark-1 text-green-1">
        <Modal.Title className="text-center">ADD ARRTICLES</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark-1">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-9">
              <Select
                isMulti
                options={options}
                className="markdown-input"
                styles={selectCustomStyle}
                onChange={(e) => setValue(e)}
                value={value}
              />
            </div>
            <div className="col-lg-3">
              <button
                className="btn form-control btn-active-green"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-dark-1">
        <Button onClick={() => openModal(false)} className="btn btn-secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddModal;
