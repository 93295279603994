export const selectCustomStyle = {
  control: (base, state) => ({
    ...base,
    background: 'transparent',
    color: '#77bdfb',
    borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
    borderColor: state.isFocused ? '#77bdfb' : '#ced4da',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: state.isFocused ? '#77bdfb' : '#77bdfb',
    },
  }),
  // input: (base) => ({
  //   ...base,
  //   backgroundColor: '#faa356',
  //   color: '#faa356',
  // }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    backgroundColor: '#242936',
    color: '#27e8a7',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: '#242936',
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: '#ced4da',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#77bdfb',
  }),
};
