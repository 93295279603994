import React from 'react';
import * as actionsCreator from '../../../../../utils/redux/topicListReducer.js/actions';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { createArticle } from '../../../../../utils/redux/articleListReducer/actions';
import { instance } from '../../../../../utils/CustomFunctions';

const RemoveModal = ({ article, isShow, openModal }) => {
  const dispatch = useDispatch();

  const handleRemove = async () => {
    await instance
      .post(`article/api/remove-from-section/${article.id}/`)
      .then((res) => {
        openModal(false);
        dispatch(actionsCreator.setTopic(res.data.data.topic));
        dispatch(createArticle(res.data.data.article));
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal show={isShow} onHide={openModal} centered>
      <Modal.Body className="bg-dark-1">
        <div className="text-blue-1">
          <strong>Are you sure you want to continue?</strong>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-dark-1">
        <Button className="btn btn-secondary" onClick={() => openModal(false)}>
          Close
        </Button>
        <Button className="btn-active-red" onClick={handleRemove}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveModal;
