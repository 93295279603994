import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { RiShareLine } from 'react-icons/ri';
import CopyModal from './CopyModal';
import WhatsappBtn from './WhatsappBtn';
import CopyBtn from './CopyBtn';

const SharingButton = ({ id, name }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const handleCloseModal = (e) => {
    setIsShowModal(false);
  };
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          className="btn form-control btn-active-orange"
          id="dropdown-basic"
        >
          <RiShareLine className="margin-right" />
          Share
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <WhatsappBtn id={id} name={name} />
          <CopyBtn id={id} name={name} setIsShowModal={setIsShowModal} />
        </Dropdown.Menu>
      </Dropdown>
      <CopyModal isShow={isShowModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default SharingButton;
