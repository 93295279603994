import React, { useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { AiFillDelete } from 'react-icons/ai';
import UpdateModal from '../update/UpdateModal';
import DeleteModal from '../delete/DeleteModal';

const Row = ({ item }) => {
  const [showModalCreate, setShowCreateModal] = useState(false);
  const [showModalDelete, setShowDeleteModal] = useState(false);

  return (
    <tr className="text-center align-middle" key={item.id}>
      <td>
        <button
          className="btn btn-secondary align-middle btn-outline-green-1"
          onClick={() => setShowCreateModal(true)}
        >
          <AiFillEdit />
        </button>
        <button
          className="btn btn-secondary align-middle btn-outline-green-1"
          onClick={() => setShowDeleteModal(true)}
        >
          <AiFillDelete />
        </button>
      </td>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <UpdateModal
        isShow={showModalCreate}
        handleCloseModal={setShowCreateModal}
        initialObj={item}
      />
      <DeleteModal
        isShow={showModalDelete}
        handleCloseModal={setShowDeleteModal}
        initialObj={item}
      />
    </tr>
  );
};

export default Row;
