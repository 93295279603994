import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import BackButton from '../../../utils/BackButton';
import Loading from '../../../utils/Loading';
import Section from './section/Section';
import CourseButtons from './topic/CourseButtons';
import PageTitle from './topic/PageTitle';
import PageDescription from './topic/PageDescription';
import { useDispatch, useSelector } from 'react-redux';
import CreateSectionModal from './section/create/CreateModal';
import UpdateModal from '../update/UpdateModal';
import DeleteModal from '../delete/DeleteModal';
import { defaultCourseUrl } from '../../../utils/urls';
import { setTopic } from '../../../utils/redux/topicListReducer.js/actions';
import { setArticles } from '../../../utils/redux/articleListReducer/actions';
import { instance } from '../../../utils/CustomFunctions';

const TopicDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const profile = useSelector((state) => state.authReducer.profile);

  //Redux
  const dispatch = useDispatch();
  const { topic } = useSelector((state) => state.topicListReducer);

  //API
  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      try {
        const response = await Promise.allSettled([
          instance.get(`article/api/list/available/`),
          instance.get(`article/api/course/details/${id}/`),
        ]);
        const status = 'fulfilled';
        const [resArticle, resTopic] = response;
        if (resTopic.status === status) {
          dispatch(setTopic(resTopic.value.data));
        }
        if (resArticle.status === status) {
          dispatch(setArticles(resArticle.value.data));
        }
        if (resArticle.status === status && resTopic.status === status) {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [id, dispatch]);

  if (isLoading) {
    return (
      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12">
              <Loading />
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section>
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-4">
              <div className="img-container">
                <img
                  src={topic.pic ? topic.pic : defaultCourseUrl}
                  alt={topic.name}
                  className="img-fluid w-100 rounded"
                  style={{ height: '350px' }}
                />
              </div>
            </div>
            <div className="col-lg-8">
              <BackButton url="/topic/list/" />
              <PageTitle name={topic.name} />
              <PageDescription description={topic.description} />
              {profile.user.is_superuser && (
                <CourseButtons
                  sections={topic.sections}
                  setIsShowCreateModal={setShowCreateModal}
                  setShowUpdateModal={setShowUpdateModal}
                  setShowDeleteModal={setShowDeleteModal}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <hr className="text-green-1" />
      <section>
        <div className="container">
          {topic.sections.map((section, index) => {
            return <Section key={section.id} section={section} index={index} />;
          })}
        </div>
      </section>
      <CreateSectionModal
        isShow={showCreateModal}
        openModal={setShowCreateModal}
        topic={topic}
      />

      <UpdateModal
        isShow={showUpdateModal}
        handleCancel={() => setShowUpdateModal(false)}
        obj={{ ...topic }}
      />
      <DeleteModal
        isShow={showDeleteModal}
        handleCancel={() => setShowDeleteModal(false)}
        initialObj={topic}
      />
    </>
  );
};

export default TopicDetails;
