import React from 'react';
import { Link } from 'react-router-dom';

const BackButton = ({ url }) => {
  return (
    <div className="row">
      <div className="col-lg-2">
        <Link to={url} className="btn btn-outline-green-1 form-control">
          Back
        </Link>
      </div>
    </div>
  );
};

export default BackButton;
