import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const ConfirmationModal = ({ isShow, setIsShow, id }) => {
  let history = useHistory();
  useEffect(() => {
    const event = setTimeout(() => {
      setIsShow(false);
    }, 2000);
    return () => {
      clearTimeout(event);
      history.push(`/article/details/${id}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal show={isShow} centered>
      <Modal.Body className="bg-dark-1 text-center">
        <strong className="text-green-1">
          Record Created Successfully. you will be redirect soon ... please wait
        </strong>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmationModal;
