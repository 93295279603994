import React from 'react';
import LoginBtn from './LoginBtn';

const Confirmation = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6">
          <div className="card card-green">
            <div className="card-body">
              <div className="row text-center">
                <div className="col-lg-12">
                  <div className="alert alert-success">
                    Password has changed succcessfully
                  </div>
                </div>
              </div>
              <LoginBtn />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
