import React from 'react';

const LoadMoreButton = ({ setPage, page }) => {
  return (
    <div className="row text-center mt-3">
      <div className="col-lg-12 ">
        <button
          className="btn btn-outline-green-1"
          onClick={() => setPage(page + 1)}
        >
          Load More Records
        </button>
      </div>
    </div>
  );
};

export default LoadMoreButton;
