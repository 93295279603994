import React from 'react';
import { Link } from 'react-router-dom';

const Back = ({ to }) => {
  return (
    <div className="row">
      <div className="col-lg-3 " />
      <div className="col-lg-2">
        <Link to={to} className="btn btn-outline-green-1">
          Back
        </Link>
      </div>
    </div>
  );
};

export default Back;
