import React, { useEffect, useState } from 'react';
import { IoIosAddCircle } from 'react-icons/io';
import CreateModal from '../create/CreateModal';
import { useDispatch, useSelector } from 'react-redux';
import * as actionsCreator from '../../../utils/redux/articleTypeReducer/actions';
import TableView from './TableView';
import { instance } from '../../../utils/CustomFunctions';

const ArticleTypeList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isShowCreateModal, setisShowCreateModal] = useState(false);

  // redux
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.articleTypeReducer);

  //API
  const fetchData = async () => {
    setIsLoading(true);
    await instance
      .get(`article/api/type/list/`)
      .then((resp) => dispatch(actionsCreator.setList(resp.data.results)))
      .then(() => setIsLoading(false))
      .catch((err) => err);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchData();
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section>
        <div className="container mt-3">
          <div className="row no-gutters">
            <div className="col-6 col-lg-6"></div>
            <div className="col-6 col-lg-6 text-right">
              <button
                className="btn btn-secondary align-middle btn-outline-green-1"
                onClick={() => setisShowCreateModal(true)}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
        </div>
      </section>
      <hr className="text-green-1" />
      <section>
        <div className="container mt-3">
          <div className="row">
            {!isLoading && list.length <= 0 ? (
              <div className="col-lg-12 text-center ">
                <h5 className="mt-5">
                  <strong className="text-red-1">
                    There is no more list match your query.
                  </strong>
                </h5>
              </div>
            ) : (
              <TableView list={list} />
            )}
          </div>
        </div>
        <CreateModal
          isShow={isShowCreateModal}
          handleCloseModal={setisShowCreateModal}
        />
      </section>
    </>
  );
};

export default ArticleTypeList;
