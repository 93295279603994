import {
  USER_LIST_SET_LIST,
  USER_LIST_UPDATE_USER,
  USER_LIST_CREATE_USER,
} from './actions';

const initialState = {
  list: [],
};

export const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LIST_SET_LIST:
      return { ...state, list: sorting(action.payload) };
    case USER_LIST_CREATE_USER:
      const newList = [...state.list, action.payload];
      return {
        ...state,
        list: sorting(newList),
      };
    case USER_LIST_UPDATE_USER:
      const obj = action.payload;
      const users = state.list.map((profile) => {
        if (profile.id === obj.id) return obj;
        else return profile;
      });
      return {
        ...state,
        list: sorting(users),
      };
    default:
      return state;
  }
};

const sorting = (list) => {
  return list.sort((a, b) => {
    if (a.user.first_name.toLowerCase() > b.user.first_name.toLowerCase())
      return +1;
    if (b.user.first_name.toLowerCase() > a.user.first_name.toLowerCase())
      return -1;
    return 0;
  });
};
