import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRouteAdminOnly = ({ children, ...rest }) => {
  let profile = useSelector((state) => state.authReducer.profile);

  if (!profile) {
    return (
      <Route {...rest}>
        <Redirect to="/login" />
      </Route>
    );
  }

  if (profile.user.is_superuser) {
    return <Route {...rest}>{children}</Route>;
  }

  return (
    <Route {...rest}>
      <Redirect to="/topic/list" />
    </Route>
  );
};

export default PrivateRouteAdminOnly;
