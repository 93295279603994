import React from 'react';
import { BiRecycle } from 'react-icons/bi';

const CancelButton = ({ handleCancel }) => {
  return (
    <button
      tabIndex={6}
      className="btn form-control btn-active-blue"
      onClick={(e) => handleCancel()}
    >
      <BiRecycle className="margin-right" />
      <strong>Reset</strong>
    </button>
  );
};

export default CancelButton;
