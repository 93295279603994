import {
  ARTICLE_LIST_SET_ARTICLES,
  ARTICLE_LIST_DELETE_ARTICLE,
  ARTICLE_LIST_CREATE_ARTICLE,
} from './actions';

const initialState = {
  articles: [],
  totalPages: 1,
  next: '',
};

export const articleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLE_LIST_SET_ARTICLES:
      return {
        ...state,
        articles: action.payload.results,
        totalPage: action.payload.total_pages,
        next: action.payload.next,
      };
    case ARTICLE_LIST_CREATE_ARTICLE:
      return {
        ...state,
        articles: [...state.articles, action.payload],
      };
    case ARTICLE_LIST_DELETE_ARTICLE:
      const ids = [...action.payload];
      return {
        ...state,
        articles: state.articles.filter((article) => {
          // if ids contains article.id, then return true
          return ids.indexOf(article.id) === -1;
        }),
      };
    default:
      return state;
  }
};
