import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Loading from '../../../utils/Loading';
import { isNullOrWhitespace } from '../../../utils/CustomFunctions';
import { instance } from '../../../utils/CustomFunctions';
import Input from '../form/Input';

const PasswordModal = ({ obj, isShow, handleCancel }) => {
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);

  const checkForm = () => {
    if (isNullOrWhitespace(password)) {
      return false;
    } else {
      return true;
    }
  };
  const clearForm = () => {
    setPassword('');
  };
  const closeModal = () => {
    setIsLoading(false);
    setIsShowConfirmation(true);
    setTimeout(() => {
      handleCancel();
    }, 3000);
  };
  const postObj = async () => {
    const isValidForm = checkForm();
    if (isValidForm) {
      setIsLoading(true);
      await instance
        .post(`user-auth/api/change-password/${obj.user.id}/`, {
          new_password: password,
        })
        .then(() => setIsLoading(false))
        .then(() => clearForm())
        .then(() => closeModal())
        .catch((err) => console.log(err.response));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postObj();
  };

  return (
    <Modal show={isShow} onHide={handleCancel} centered>
      <form onSubmit={handleSubmit}>
        {isLoading ? (
          <Modal.Body className="bg-dark-1">
            <Loading />
          </Modal.Body>
        ) : (
          <Modal.Body className="bg-dark-1">
            <div className="row">
              <div className="col-lg-12">
                {isShowConfirmation ? (
                  <span className="text-green-1">
                    <strong>Transaction Done Successfully</strong>
                  </span>
                ) : (
                  <Input
                    name="password"
                    label="Password"
                    required={true}
                    placeholder=""
                    type="password"
                    value={password}
                    setValue={setPassword}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer className="bg-dark-1">
          <Button className="btn btn-secondary" onClick={handleCancel}>
            Close
          </Button>
          {!isShowConfirmation && (
            <Button className="btn btn-active-green" type="submit">
              Save
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default PasswordModal;
