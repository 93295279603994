import React from 'react';

const FistNameInput = ({ setValue, value, validation, handleValidation }) => {
  const handleOnChange = (e) => {
    setValue(e.target.value);
    handleValidation(false);
  };
  return (
    <div className="row mt-2">
      <div className="col-lg-12">
        <label className="mt-2 text-green-1" htmlFor="firstName">
          First Name
        </label>
        <input
          type="firstName"
          className="form-control markdown-input mt-1"
          placeholder="Fist Name ..."
          name="firstName"
          id="firstName"
          value={value}
          onChange={(e) => handleOnChange(e)}
          tabIndex={3}
        />
        {validation.value && (
          <span className="text-red-1">{validation.error}</span>
        )}
      </div>
    </div>
  );
};

export default FistNameInput;
