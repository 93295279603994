import React from 'react';
import { domainUrl } from './urls';
import { AiOutlineWhatsApp } from 'react-icons/ai';

const WhatsappBtn = ({ id, name }) => {
  return (
    <a
      href={`whatsapp://send?text=${domainUrl}article/public/${id}/%0a` + name}
      data-action="share/whatsapp/"
      target="_blank"
      className="dropdown-item text-green-1"
      rel="noopener noreferrer"
    >
      <AiOutlineWhatsApp className="margin-right" />
      Whatsapp
    </a>
  );
};

export default WhatsappBtn;
