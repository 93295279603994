import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { userListReducer } from './utils/redux/userListReducer/userListReducer';
import { articleListReducer } from './utils/redux/articleListReducer/articleListReducer';
import { topicListReducer } from './utils/redux/topicListReducer.js/topicListReducer';
import { articleTypeReducer } from './utils/redux/articleTypeReducer/articleTypeReducer';
import { authReducer } from './utils/redux/authReducer/authReducer';
import { forgetPassReducer } from './utils/redux/forgetPassReducer/forgetPassReducer';
import thunk from 'redux-thunk';
import { isTokenValid } from './utils/redux/authReducer/actions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  userListReducer,
  articleListReducer,
  topicListReducer,
  articleTypeReducer,
  authReducer,
  forgetPassReducer,
});
const store = createStore(
  rootReducer,
  compose(composeEnhancers(applyMiddleware(thunk)))
);

store.dispatch(isTokenValid());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
