import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Loading from '../../../utils/Loading';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Title from './Title';
import BackButton from './BackButton';
import { instance } from '../../../utils/CustomFunctions';
const ArticlePublic = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [markdown, setMarkdown] = useState('');

  //API
  const getData = async () => {
    setIsLoading(true);
    await instance
      .get(`article/api/details/${id}/`)
      .then((res) => {
        setArticle(res.data);
        setMarkdown(res.data.html);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  //Use Effect
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <section className="mt-5">
        <Loading />
      </section>
    );
  }

  return (
    <>
      <section>
        <div className="container mt-2">
          <BackButton article={article} />
          <div className="row no-gutters mt-3">
            <Title article={article} />
          </div>
        </div>
      </section>
      <hr className="text-green-1" />
      <section>
        <div className="container mt-3 mb-5">
          <div className="row">
            <div className="col-lg-12">
              <article className="result text-green-1 bordered mt-1">
                <ReactMarkdown
                  children={markdown}
                  remarkPlugins={[remarkGfm]}
                />
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ArticlePublic;
