import React from 'react';

const NoDataError = () => {
  return (
    <div className="col-lg-12 text-center ">
      <h5 className="mt-5">
        <strong className="text-red-1">
          There is no more articles match your query.
        </strong>
      </h5>
    </div>
  );
};

export default NoDataError;
