import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as actionsCreator from '../../../utils/redux/topicListReducer.js/actions';
import { Form } from 'react-bootstrap';
import { instance } from '../../../utils/CustomFunctions';

// this model is being used in two pages, details  & list
const UpdateModal = ({ obj, isShow, handleCancel }) => {
  const [topic, setTopic] = useState({
    id: obj.id,
    name: obj.name,
    description: obj.description,
    pic: null,
    is_clear_pic: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setTopic({
      id: obj.id,
      name: obj.name,
      description: obj.description,
      pic: null,
      is_clear_pic: false,
    });
  }, [obj]);

  const postPic = async (e) => {
    const fd = new FormData();
    fd.append('pic', topic.pic);
    await instance
      .post(`article/api/course/pic/update/${topic.id}/`, fd)
      .then((res) => {
        dispatch(actionsCreator.setTopic(res.data.data)); //details page
        dispatch(actionsCreator.updateTopic(res.data.data));
      })
      .then(() => handleCancel())
      .catch((err) => console.log(err));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    await instance
      .post(`article/api/course/update/${topic.id}/`, topic)
      .then((res) => {
        dispatch(actionsCreator.updateTopic(res.data.data)); //list page
        dispatch(actionsCreator.setTopic(res.data.data)); //details page
      })
      .then(() => (topic.pic ? postPic() : handleCancel()))
      .catch((err) => console.log(err));
  };

  const handleOnChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setTopic({ ...topic, [e.target.name]: value });
  };

  return (
    <Modal show={isShow} onHide={handleCancel} centered>
      <form onSubmit={handleOnSubmit}>
        <Modal.Body className="bg-dark-1">
          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="name" className="label-control text-green-1">
                Name
              </label>
              <input
                required={true}
                type="text"
                className="search-input"
                name="name"
                id="name"
                value={topic.name}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <label
                htmlFor="description"
                className="label-control text-green-1"
              >
                Description
              </label>
              <textarea
                name="description"
                id="description"
                rows="10"
                className=" w-100 search-input"
                value={topic.description}
                onChange={(e) => handleOnChange(e)}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group
                controlId="formFile"
                className="mb-3 mt-2 btn-outline-green-1"
              >
                <Form.Label>Picture</Form.Label>
                <Form.Control
                  type="file"
                  className="search-input"
                  onChange={(e) =>
                    setTopic({ ...topic, pic: e.target.files[0] })
                  }
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group
                className="mb-3 text-green-1"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  label="Remove Picture?"
                  name="is_clear_pic"
                  onClick={(e) => handleOnChange(e)}
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-dark-1">
          <Button className="btn btn-secondary" onClick={handleCancel}>
            Close
          </Button>
          <Button className=" btn-active-green" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UpdateModal;
