import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Loading from '../../../../../utils/Loading';
import * as actionsCreator from '../../../../../utils/redux/topicListReducer.js/actions';
import { useDispatch } from 'react-redux';
import { instance } from '../../../../../utils/CustomFunctions';

const UpdateModal = ({ section, isShow, openModal }) => {
  //state
  const [loading, setLoading] = useState(false);
  const [obj, setObj] = useState({
    name: section.name,
    sequance: section.sequance,
  });

  //redux
  const dispatch = useDispatch();

  // form
  const handleChange = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await instance
      .post(`article/api/section/update/${section.id}/`, obj)
      .then((res) => dispatch(actionsCreator.setTopic(res.data.data)))
      .then(() => setLoading(false))
      .then(() => openModal(false))
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={isShow} onHide={openModal} centered>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="bg-dark-1">
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <label htmlFor="name" className="label-control text-green-1">
                    Name
                  </label>
                  <input
                    required={true}
                    type="text"
                    className="search-input"
                    name="name"
                    id="name"
                    value={obj.name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label htmlFor="name" className="label-control text-green-1">
                    Sequance
                  </label>
                  <input
                    required={true}
                    type="text"
                    className="search-input"
                    name="sequance"
                    id="sequance"
                    value={obj.sequance}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark-1">
          <Button
            onClick={() => openModal(false)}
            className="btn btn-secondary"
          >
            Close
          </Button>
          <Button className="btn-active-green" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UpdateModal;
