import React from 'react';

const SearchInput = ({ setQuery, query }) => {
  return (
    <>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="search-input align-middle"
        placeholder="Search here..."
      />
    </>
  );
};

export default SearchInput;
