import React from 'react';
import ProfileImage from '../../../utils/ProfileImage';
import { AiFillDelete } from 'react-icons/ai';
import * as actionsCreator from '../../../utils/redux/topicListReducer.js/actions';
import { useDispatch } from 'react-redux';
import { instance } from '../../../utils/CustomFunctions';

const Card = ({ access }) => {
  const dispatch = useDispatch();
  const handleDelete = async () => {
    await instance
      .delete(`article/api/access/delete/${access.id}/`)
      .then((res) => dispatch(actionsCreator.updateTopic(res.data.data)))
      .catch((error) => console.log(error));
  };
  return (
    <div className="card bg-dark-1">
      <div className="card-body">
        <div className="row">
          <div className="col-9 my-auto">
            <h5 className="text-green-1">
              <ProfileImage pic={access.profile.pic} />
              {access.profile.user.first_name}
            </h5>
          </div>
          <div className="col-3 text-right">
            <button
              className="btn btn-active-red mt-2"
              type="button"
              onClick={handleDelete}
            >
              <AiFillDelete />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
