import React from 'react';
import { MdPublic } from 'react-icons/md';
import { Link } from 'react-router-dom';
const PublicBtn = ({ id }) => {
  return (
    <Link to={`/article/public/${id}`} className="dropdown-item text-green-1">
      <MdPublic className="margin-right" />
      Public View
    </Link>
  );
};

export default PublicBtn;
