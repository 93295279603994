import React from 'react';

const PageDescription = ({ description }) => {
  return (
    <div className="row">
      <div className="col-lg-12 text-blue-1 my-auto">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default PageDescription;
