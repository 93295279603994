export const ARTICLE_LIST_SET_ARTICLES = 'ARTICLE_LIST_SET_ARTICLES';
export const ARTICLE_LIST_DELETE_ARTICLE = 'ARTICLE_LIST_DELETE_ARTICLE';
export const ARTICLE_LIST_CREATE_ARTICLE = 'ARTICLE_LIST_CREATE_ARTICLE';

export const setArticles = (list) => {
  return {
    type: ARTICLE_LIST_SET_ARTICLES,
    payload: list,
  };
};

export const createArticle = (obj) => {
  return {
    type: ARTICLE_LIST_CREATE_ARTICLE,
    payload: obj,
  };
};

export const deleteArticle = (id) => {
  return {
    type: ARTICLE_LIST_DELETE_ARTICLE,
    payload: id,
  };
};
