import React from 'react';
import { Link } from 'react-router-dom';

const LoginBtn = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <Link to="/" className="btn btn-active-green form-control">
          Back To Home Page
        </Link>
      </div>
    </div>
  );
};

export default LoginBtn;
