import React from 'react';

const PageTitle = ({ name }) => {
  return (
    <div className="row mt-3">
      <div className="col-lg-12 text-blue-1">
        <h1>{name}</h1>
      </div>
    </div>
  );
};

export default PageTitle;
