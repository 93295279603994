import React from 'react';

const NameInput = ({ name, setName, validation, handleValidation }) => {
  const handleOnChange = (e) => {
    setName(e.target.value);
    handleValidation(false);
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <input
          type="text"
          className="form-control markdown-input mt-1"
          placeholder="Write Article Title here ..."
          tabIndex={1}
          name="name"
          id="name"
          value={name}
          onChange={(e) => handleOnChange(e)}
        />
        {validation.value && (
          <span className="text-red-1">{validation.error}</span>
        )}
      </div>
    </div>
  );
};

export default NameInput;
