import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as actionsCreator from '../../../utils/redux/articleTypeReducer/actions';
import { useDispatch } from 'react-redux';
import { instance } from '../../../utils/CustomFunctions';

const CreateModal = ({ isShow, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [obj, setObj] = useState({
    name: '',
    description: '',
    pic: null,
    color: '',
  });

  const handleOnChange = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    await instance
      .post(`article/api/type/create/`, obj)
      .then((res) => dispatch(actionsCreator.createObj(res.data.data)))
      .then(() => handleCloseModal(false))
      .catch((err) => console.log(err.response));
    setObj({
      name: '',
      description: '',
      pic: null,
      color: '',
    });
  };

  return (
    <Modal show={isShow} onHide={handleCloseModal} centered>
      <form onSubmit={handleOnSubmit}>
        <Modal.Body className="bg-dark-1">
          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="name" className="label-control text-green-1">
                Name
              </label>
              <input
                required={true}
                type="text"
                className="search-input"
                name="name"
                id="name"
                value={obj.name}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="name" className="label-control text-green-1">
                Color
              </label>
              <input
                required={true}
                type="text"
                className="search-input"
                name="color"
                id="color"
                value={obj.color}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>
          <label htmlFor="description" className="label-control text-green-1">
            Description
          </label>
          <textarea
            name="description"
            id="description"
            rows="10"
            className=" w-100 search-input"
            value={obj.description}
            onChange={(e) => handleOnChange(e)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary"
            onClick={() => handleCloseModal(false)}
          >
            Close
          </Button>
          <Button className="btn btn-active-green" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default CreateModal;
