import React from 'react';
import Article from './Article';

const Cards = ({ articles }) => {
  return (
    <>
      {articles.map((article, index) => {
        return <Article key={index} {...article} />;
      })}
    </>
  );
};

export default Cards;
