import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import Row from './Row';
import { useSortableData } from './useSortableData';

const TableView = ({ list }) => {
  const [types, setTypes] = useState(list);
  const { sortedList, requestSort } = useSortableData(types, null);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTypes(list);
    }
    return () => (mounted = false);
  }, [list]);

  return (
    <Table responsive bordered className="justify-content-center">
      <thead>
        <tr>
          <th width={'10%'}>Action</th>
          <th onClick={() => requestSort('id')}>ID</th>
          <th onClick={() => requestSort('name')}>Name</th>
        </tr>
      </thead>
      <tbody>
        {sortedList.map((item) => {
          return <Row key={item.id} item={item} />;
        })}
      </tbody>
    </Table>
  );
};

export default TableView;
