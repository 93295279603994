import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BackButton = ({ article }) => {
  const profile = useSelector((state) => state.authReducer.profile);

  const getBackUrl = () => {
    if (article.section) {
      return `/topic/details/${article.section.course.id}/`;
    } else {
      return `/topic/list/`;
    }
  };

  if (!profile) {
    return <></>;
  }
  return (
    <div className="row">
      <div className="col-lg-2">
        <Link to={getBackUrl} className="btn btn-outline-green-1 form-control">
          Back
        </Link>
      </div>
    </div>
  );
};

export default BackButton;
