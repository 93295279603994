import React, { useEffect, useState } from 'react';
import LayoutButtons from '../../../utils/LayoutButtons';
import SearchInput from './components/SearchInput';
import User from '../user/User';
import Loading from '../../../utils/Loading';
import Table from '../../../utils/Table';
import { columns } from './columns';
import { IoIosAddCircle } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreaters from '../../../utils/redux/userListReducer/actions';
import CreateModal from '../modal/CreateModal';
import { instance } from '../../../utils/CustomFunctions';

const UserList = () => {
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [isCardLayout, setIsCardLayout] = useState(true);
  const [isShowCreateModal, setIsShowCreateModal] = useState(false);

  // reudx
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.userListReducer);
  const filteredList = list.filter(
    (profile) =>
      profile.user.first_name.includes(query) ||
      profile.user.last_name.includes(query) ||
      profile.mobile.includes(query) ||
      profile.user.email.includes(query)
  );

  //api
  const fetchData = async () => {
    setIsLoading(true);
    await instance
      .get(`user-auth/api/list/?q=${query}`)
      .then((res) => {
        console.log('res');
        dispatch(actionCreaters.setUsers(res.data.results));
      })
      .then(() => setIsLoading(false))

      .catch((error) => console.log(error));
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchData();
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //actions
  const handleLayout = () => {
    setIsCardLayout(!isCardLayout);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <section>
        <div className="container mt-3">
          <div className="row no-gutters">
            <div className="col-6 col-lg-6">
              <SearchInput setQuery={setQuery} value={query} />
            </div>
            <div className="col-6 col-lg-6 text-right">
              <button
                className="btn btn-secondary align-middle btn-outline-green-1"
                onClick={() => setIsShowCreateModal(true)}
              >
                <IoIosAddCircle />
              </button>

              <LayoutButtons
                isCardLayout={isCardLayout}
                handleLayout={handleLayout}
              />
            </div>
          </div>
        </div>
      </section>
      <hr className="text-green-1" />
      <section>
        <div className="container mt-3">
          <div className="row">
            {!isLoading && filteredList.length <= 0 ? (
              <div className="col-lg-12 text-center ">
                <h5 className="mt-5">
                  <strong className="text-red-1">
                    There is no more list match your query.
                  </strong>
                </h5>
              </div>
            ) : (
              <>
                {isCardLayout ? (
                  <>
                    {filteredList.map((obj, id) => {
                      return <User key={id} obj={obj} />;
                    })}
                  </>
                ) : (
                  <Table data={filteredList} columns={columns} />
                )}
              </>
            )}
          </div>
        </div>
        <CreateModal
          isShow={isShowCreateModal}
          setIsShowCreateModal={setIsShowCreateModal}
        />
      </section>
    </>
  );
};

export default UserList;
