import { instance } from '../../../utils/CustomFunctions';

export const FORGET_PASSWORD_SAVE_USERNAME = 'FORGET_PASSWORD_SAVE_USERNAME';
export const FORGET_PASSWORD_SET_OTP = 'FORGET_PASSWORD_SET_OTP';
export const FORGET_PASSWORD_CLEAR = 'FORGET_PASSWORD_CLEAR';

export const saveUsername = (username) => {
  return async (dispatch) => {
    await instance
      .post(`user-auth/api/send/otp/`, { username: username })
      .then((res) =>
        dispatch({
          type: FORGET_PASSWORD_SAVE_USERNAME,
          payload: username,
        })
      )
      .catch((err) => console.log(err));
  };
};

export const saveOtp = (otp, setLoading, setError, history) => {
  return async (dispatch) => {
    await instance
      .post(`user-auth/api/verify/otp/`, { otp: otp })
      .then(() =>
        dispatch({
          type: FORGET_PASSWORD_SET_OTP,
          payload: otp,
        })
      )
      .then(() => setLoading(false))
      .then(() => history.push('/reset-password'))
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  };
};

export const savePass = (obj, setLoading, setError, history) => {
  return async (dispatch) => {
    await instance
      .post(`user-auth/api/reset-password/`, obj)
      .then(() =>
        dispatch({
          type: FORGET_PASSWORD_CLEAR,
        })
      )
      .then(() => setLoading(false))
      .then(() => history.push('/reset/confirmation'))
      .catch((err) => setError(err.response.data.message));
  };
};
