import React, { useState } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import ProfileImage from '../../utils/ProfileImage';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { logout } from '../../utils/redux/authReducer/actions';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ChangePasswordModal from './ChangePasswordModal';
import UpdateProfileModal from './UpdateProfileModal';

const NavigationBar = () => {
  let profile = useSelector((state) => state.authReducer.profile);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = () => {
    dispatch(logout(history));
  };

  if (!profile) {
    return <></>;
  }

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Link to="/" className="remove-link-decoration">
            <h1 className="text-green-1 ">Warehouse</h1>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {profile?.user.is_superuser && (
                <>
                  <Link
                    to="/user/list"
                    className="nav-link remove-link-decoration text-grey-1"
                  >
                    <strong>USERS</strong>
                  </Link>
                  <Link
                    to="/topic/list"
                    className="nav-link  remove-link-decoration text-grey-1"
                  >
                    <strong>TOPICS</strong>
                  </Link>
                  <Link
                    to="/type/list"
                    className="nav-link remove-link-decoration text-grey-1"
                  >
                    <strong>TYPES</strong>
                  </Link>
                </>
              )}
            </Nav>
            <Nav>
              {<ProfileImage pic={profile.pic} />}
              <NavDropdown
                title={`Hello, ${profile.user.first_name}`}
                id="collasible-nav-dropdown"
                className="align-middle mt-1"
              >
                <NavDropdown.Item
                  href="#action/3.1"
                  className="dropdown-item text-green-1"
                  onClick={() => setOpenPasswordModal(true)}
                >
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#action/3.4"
                  className="dropdown-item text-green-1"
                  onClick={() => setOpenUpdateModal(true)}
                >
                  Update Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  className="dropdown-item text-green-1"
                  onClick={handleLogout}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ChangePasswordModal
        isShow={openPasswordModal}
        openModal={setOpenPasswordModal}
      />
      <UpdateProfileModal
        isShow={openUpdateModal}
        openModal={setOpenUpdateModal}
      />
    </>
  );
};

export default NavigationBar;
