import React, { useEffect, useState } from 'react';
import LayoutButtons from './components/LayoutButtons';
import SearchInput from './components/SearchInput';
import Loading from '../../../utils/Loading';
import Table from '../../../utils/Table';
import { columns } from './components/columns';
import { IoIosAddCircle } from 'react-icons/io';
import { Link } from 'react-router-dom';
import NoDataError from './components/NoDataError';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../../../utils/redux/articleListReducer/actions';
import LoadMoreButton from './components/LoadMoreButton';
import Cards from './components/Cards';
import { instance } from '../../../utils/CustomFunctions';

const ArticleList = () => {
  // State
  const [query, setQuery] = useState(sessionStorage.getItem('query') || '');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isCardLayout, setIsCardLayout] = useState(
    JSON.parse(sessionStorage.getItem('isCardLayout')) || true
  );

  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, []);

  //Redux
  const { articles, next } = useSelector((state) => state.articleListReducer);
  const dispatch = useDispatch();

  // API
  const fetchData = async () => {
    setIsLoading(true);
    instance.defaults.headers.common.Authorization = `jwt ${token}`;
    await instance
      .get(`article/api/list/?p=${page}&q=${query}`)
      .then((res) => {
        let list = [];
        if (query && page === 1) {
          list = res.data.results;
        } else {
          const compineList = [...articles, ...res.data.results];
          const ids = compineList.map((item) => item.id);
          const uniqID = [...new Set(ids)];
          list = uniqID.map((id) => {
            return compineList.find((item) => item.id === id);
          });
        }
        dispatch(
          actionCreators.setArticles({
            results: list,
            total_pages: res.data.total_pages,
            next: res.data.next,
          })
        );
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  //initiate Data
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchData();
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // get Default Layout
  useEffect(() => {
    setIsCardLayout(JSON.parse(sessionStorage.getItem('isCardLayout')) || true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //getQuery from sessonStorage
  useEffect(() => {
    setQuery(sessionStorage.getItem('query') || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery(query);
    sessionStorage.setItem('query', query);
    setPage(1);
    fetchData();
  };
  const handleLayout = () => {
    setIsCardLayout(!isCardLayout);
    sessionStorage.setItem('isCardLayout', JSON.stringify(!isCardLayout)); // session storage
  };

  if (!articles) {
    return <Loading />;
  }

  return (
    <>
      <section>
        <div className="container mt-3">
          <div className="row no-gutters">
            <div className="col-6 col-lg-6">
              <form onSubmit={(e) => handleSubmit(e)}>
                <SearchInput query={query} setQuery={setQuery} />
              </form>
            </div>
            <div className="col-6 col-lg-6 text-right">
              <Link to="/article/create/">
                <button className="btn btn-secondary align-middle btn-outline-green-1">
                  <IoIosAddCircle />
                </button>
              </Link>
              <LayoutButtons
                url="/article/create/"
                isCardLayout={isCardLayout}
                handleLayout={handleLayout}
              />
            </div>
          </div>
        </div>
      </section>
      <hr className="text-green-1" />
      <section>
        <div className="container mt-3">
          <div className="row">
            {!isLoading && articles.length <= 0 ? (
              <NoDataError />
            ) : (
              <>
                {isCardLayout ? (
                  <Cards articles={articles} />
                ) : (
                  <Table data={articles} columns={columns} />
                )}
                {isLoading && <Loading />}
                {next && <LoadMoreButton page={page} setPage={setPage} />}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ArticleList;
