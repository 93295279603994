import {
  TOPIC_LIST_SET_LIST,
  TOPIC_LIST_UPDATE_TOPIC,
  TOPIC_LIST_CREATE_TOPIC,
  TOPIC_LIST_DELETE_TOPIC,
  TOPIC_LIST_SET_TOPIC,
} from './actions';

const initialState = {
  list: [],
  topic: null,
};

export const topicListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOPIC_LIST_SET_LIST:
      const list = action.payload.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return +1;
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
        return 0;
      });
      return { ...state, list: list };
    case TOPIC_LIST_CREATE_TOPIC:
      const newList = [...state.list, action.payload];
      const sortList = newList.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return +1;
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
        return 0;
      });
      return {
        ...state,
        list: sortList,
      };
    case TOPIC_LIST_UPDATE_TOPIC:
      const newObj = action.payload;
      return {
        ...state,
        list: state.list.map((obj) => {
          if (obj.id === newObj.id) return newObj;
          else return obj;
        }),
      };
    case TOPIC_LIST_DELETE_TOPIC:
      return {
        ...state,
        list: state.list.filter((obj) => obj.id !== action.payload),
      };
    case TOPIC_LIST_SET_TOPIC:
      return {
        ...state,
        topic: action.payload,
      };
    default:
      return state;
  }
};
