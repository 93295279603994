import {
  FORGET_PASSWORD_CLEAR,
  FORGET_PASSWORD_SAVE_USERNAME,
  FORGET_PASSWORD_SET_OTP,
} from './actions';

const initialState = {
  username: null,
  otp: '',
  password: '',
  repeatPassword: '',
};

export const forgetPassReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_SAVE_USERNAME:
      return {
        ...state,
        username: action.payload,
      };

    case FORGET_PASSWORD_SET_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case FORGET_PASSWORD_CLEAR:
      return {
        ...state,
        username: '',
        otp: '',
        password: '',
        repeatPassword: '',
      };

    default:
      return state;
  }
};
