import React from 'react';
import { AiFillDelete } from 'react-icons/ai';

const DeleteButton = ({ handleDeleteModal }) => {
  return (
    <button
      tabIndex={8}
      className="btn form-control btn-active-red"
      onClick={(e) => handleDeleteModal()}
    >
      <AiFillDelete className="margin-right" />
      <strong>Delete</strong>
    </button>
  );
};

export default DeleteButton;
