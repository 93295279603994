import React from 'react';

const Title = ({ article }) => {
  return (
    <div className="col-lg-6">
      <h1 className="text-green-1">Article Details</h1>
      <h5 className="text-blue-1">
        <strong>{article && article.name}</strong>
      </h5>
    </div>
  );
};

export default Title;
