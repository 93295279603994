import React from 'react';
import { selectCustomStyle } from '../../../../utils/SelectCustomStyle';
import Select from 'react-select';

const SelectInput = ({
  types,
  type,
  setType,
  validation,
  handleValidation,
}) => {
  const handleOnChange = (e) => {
    setType(e);
    handleValidation(false);
  };
  return (
    <div className="row mt-2">
      <div className="col-lg-12">
        <Select
          options={types}
          className="markdown-input"
          styles={selectCustomStyle}
          onChange={(e) => handleOnChange(e)}
          value={type}
          tabIndex={2}
        />
        {validation.value && (
          <span className="text-red-1">{validation.error}</span>
        )}
      </div>
    </div>
  );
};

export default SelectInput;
