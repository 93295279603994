import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as actionsCreator from '../../../utils/redux/topicListReducer.js/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { instance } from '../../../utils/CustomFunctions';

const DeleteModal = ({ isShow, handleCancel, initialObj }) => {
  const [id, setID] = useState(initialObj.id);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = async (e) => {
    await instance
      .delete(`article/api/course/delete/${id}/`)
      .then((res) => dispatch(actionsCreator.deleteTopic(res.data.data.id)))
      .then(() => handleCancel())
      .then(() => history.push('/topic/list'))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setID(initialObj.id);
    }
    return () => (mounted = false);
  }, [initialObj]);

  return (
    <Modal show={isShow} onHide={handleCancel} centered>
      <Modal.Body className="bg-dark-1">
        <div className="text-blue-1">
          <strong>Are you sure you want to continue?</strong>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-dark-1">
        <Button className="btn btn-secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button className="btn-active-red" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
