import React from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';

const ActionBtns = ({ eventUpdate, eventPassword }) => {
  return (
    <div className="col-4 text-right">
      <button
        className="btn btn-outline-white-1 mt-2"
        type="button"
        onClick={eventUpdate}
      >
        <AiFillEdit />
      </button>
      <button
        className="btn btn-outline-white-1 mt-2"
        type="button"
        onClick={eventPassword}
      >
        <RiLockPasswordLine />
      </button>
    </div>
  );
};

export default ActionBtns;
