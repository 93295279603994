import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Loading from '../../utils/Loading';
import { useSelector, useDispatch } from 'react-redux';
import { updateMyProfile } from '../../utils/redux/authReducer/actions';

const UpdateProfileModal = ({ isShow, openModal }) => {
  const profile = useSelector((state) => state.authReducer.profile);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [obj, setObj] = useState({
    first_name: profile.user.first_name,
    last_name: profile.user.last_name,
    mobile: profile.mobile,
    email: profile.user.email,
    isTrainee: profile.is_trainee,
    pic: null,
  });

  const handleChange = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(updateMyProfile(obj, profile.user.id));
    setIsLoading(false);
    openModal(false);
  };

  return (
    <Modal show={isShow} onHide={openModal} centered>
      <form onSubmit={handleSubmit}>
        {isLoading ? (
          <Modal.Body className="bg-dark-1">
            <Loading />
          </Modal.Body>
        ) : (
          <Modal.Body className="bg-dark-1">
            <div className="row">
              <div className="col-lg-12">
                <>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <label
                        htmlFor="first_name"
                        className="label-control text-green-1"
                      >
                        First Name
                      </label>
                      <input
                        required={true}
                        type="text"
                        className="search-input"
                        name="first_name"
                        id="first_name"
                        value={obj.first_name}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <label
                        htmlFor="last_name"
                        className="label-control text-green-1"
                      >
                        Last Name
                      </label>
                      <input
                        required={true}
                        type="text"
                        className="search-input"
                        name="last_name"
                        id="last_name"
                        value={obj.last_name}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <label
                        htmlFor="email"
                        className="label-control text-green-1"
                      >
                        Email
                      </label>
                      <input
                        required={true}
                        type="email"
                        className="search-input"
                        name="email"
                        id="email"
                        value={obj.email}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <label
                        htmlFor="email"
                        className="label-control text-green-1"
                      >
                        Mobile
                      </label>
                      <input
                        required={true}
                        type="mobile"
                        className="search-input"
                        name="mobile"
                        id="mobile"
                        value={obj.mobile}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <Form.Group
                        controlId="formFile"
                        className="mb-3 mt-2 btn-outline-green-1"
                      >
                        <Form.Label>Picture</Form.Label>
                        <Form.Control
                          type="file"
                          className="search-input"
                          onChange={(e) =>
                            setObj({ ...obj, pic: e.target.files[0] })
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer className="bg-dark-1">
          <Button
            className="btn btn-secondary"
            onClick={() => openModal(false)}
          >
            Close
          </Button>
          <Button className="btn btn-active-green" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default UpdateProfileModal;
