import React from 'react';
import { GiCancel } from 'react-icons/gi';
import { Link } from 'react-router-dom';

const CancelButton = () => {
  return (
    <div className="col-lg-2 text-right mt-auto">
      <Link to="/">
        <button className="btn form-control btn-active-red" tabIndex={6}>
          <GiCancel className="margin-right" />
          Cancel
        </button>
      </Link>
    </div>
  );
};

export default CancelButton;
