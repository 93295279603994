import React from 'react';

const TextAreaInput = ({
  description,
  setDescription,
  setMarkdown,
  validation,
}) => {
  const handleOnChange = (e) => {
    setDescription(e.target.value);
    setMarkdown(e.target.value);
  };
  return (
    <>
      <textarea
        className="form-control markdown-input mt-1"
        style={{ height: '100%' }}
        value={description}
        rows={20}
        tabIndex={4}
        onChange={handleOnChange}
      ></textarea>
      {validation.value && (
        <span className="text-red-1">{validation.error}</span>
      )}
    </>
  );
};

export default TextAreaInput;
